import styled from "styled-components";
import ServerUtil from "../../../common/serverUtil";
import StoreUser from "../../redux/store/storeUser";
import assert from "assert";
import { useContext, useEffect, useState } from "react";
import ProjectFrame from "../gui/projectFrame";
import { GlobalContext } from "../entry/systemEntry";
import GloballDialog from "../../../common/component/dialog/globalDialog";
import AppReader from "../gui/appReader";
import ModelUtil from "../develop/function/util/modelUtil";
import ModelElementUtil from "../develop/function/util/modelElementUtil";
import NodeApp from "../develop/function/editor/nodeApp";
import StoreProject from "../../redux/store/storeProject";
import LocalStrageUtil from "../../util/localStrageUtil";
import NodeLauncher from "../develop/function/editor/release/nodeLauncher";

namespace DirectManager {

    type ReadState = 'load' | 'success' | 'failure';

    export const ProjectComponnet = (props: {
        query: URLSearchParams;
    }) => {

        const [devmd, setDevmd] = useState<StoreUser.Devmd | null>(null);
        const [project, setProject] = useState<StoreProject.Props | null>(null);

        useEffect(() => {
            const hashKey = props.query.get('v') as string;
            let regionStr = props.query.get('region') as string;
            // console.log(regionStr);
            const region = regionStr != null ? getRegionFromQuery(regionStr) : LocalStrageUtil.getActiveRegionDef();

            const devmdSeq = ServerUtil.getDecryptionedDevmdHash(hashKey, region);
            // console.log(props.hashKey);
            // console.log(devmdSeq);
            findDevmdFromSeq(devmdSeq, region).then((res) => {
                setDevmd(res);
                // console.log(res.source);
                // const source = ServerUtil.b64_to_utf8(res.source);
                const source = ServerUtil.unZip(res.source);
                setProject(JSON.parse(source));
                // console.log(source);
                // store.project = JSON.parse(source);
                // dispatcher.updateStore();
                document.title = res.name;
            });
        }, []);
        return (
            <_Wrap>
                <GloballDialog />
                {project == null ? <></> : <ProjectFrame.Component prjRootWrap={project.rootData} devmd={devmd} />}
                {/* {project == null ? <></> : <ProjectFrame.Component prjRootWrap={project.rootData} />} */}
            </_Wrap>
        );
    }

    type ReaderArgs = {
        projectRootWrap: ModelUtil.WrapElement;
        launcherNo: number;
    }
    export const AppComponnet = (props: {
        query: URLSearchParams;
    }) => {
        // const { store, setStore, dispatcher } = useContext(GlobalContext);
        const [readerArgs, setReaderArgs] = useState<ReaderArgs | null>(null);
        const [readState, setReadState] = useState<ReadState>('load');

        useEffect(() => {
            const hashKey = props.query.get('v') as string;
            let regionStr = props.query.get('region') as string;
            // console.log(regionStr);
            const region = regionStr != null ? getRegionFromQuery(regionStr) : LocalStrageUtil.getActiveRegionDef();
            const [devSeq, launcherNo] = ServerUtil.getDecryptionedDevLaunchHash(hashKey, region);
            // console.log(`devSeq:${devSeq}, appSeq:${appSeq}`);
            if (devSeq !== -1 && launcherNo !== -1) {
                findDevmdFromSeq(devSeq, region).then((res) => {
                    // const source = ServerUtil.b64_to_utf8(res.source);
                    const source = ServerUtil.unZip(res.source);
                    // console.log(source);
                    const module = JSON.parse(source) as StoreProject.Props;
                    const launchers = ModelElementUtil.getInnerWrapFixed(module.rootData, 'release', 'launchers').data as ModelUtil.NodeGenericItems;
                    const launcher = launchers.items
                        .map(l => l.data as NodeLauncher.Data)
                        .find(l => {
                            return l.no === launcherNo;
                        });
                    assert(launcher != undefined, `launcherがlaunchers.itemsから見つからなかった[appSeq: ${launcherNo}]`);
                    // store.project = JSON.parse(source);
                    // dispatcher.updateStore();
                    setReaderArgs({
                        projectRootWrap: module.rootData,
                        launcherNo: launcher.no
                    });
                    document.title = launcher.name;
                    setReadState('success');
                });
            } else {
                setReadState('failure');
            }
        }, []);
        return (
            <_Wrap>
                {(() => {
                    switch (readState) {
                        case 'load': return <_Message>Loading application...</_Message>;
                        case 'failure': return <_Message>Application not found...</_Message>;
                        case 'success': return (
                            readerArgs == null ? <></> : (
                                <_AppFrame>
                                    <AppReader.Component projectRootWrap={readerArgs.projectRootWrap} launchNo={readerArgs.launcherNo} />
                                </_AppFrame>
                            )
                        )
                    }
                })()}
            </_Wrap >
        );
    }

    /** ユーザが管理している開発モジュールリストを取得 */
    const findDevmdFromSeq = async (seq: number, region: LocalStrageUtil.RegionDef) => {
        const response = await ServerUtil.sendQueryRequestToAPI(
            'select',
            `SELECT seq, owner, name, source FROM devmd WHERE seq = ${seq}`,
            region
        );
        const results: StoreUser.Devmd[] = await response.json();
        assert(results.length === 1, 'findDevmdFromSeqの検索結果が見つからない');
        return results[0];
    };

    const getRegionFromQuery = (regionStr: string):LocalStrageUtil.RegionDef => {
        const str = ServerUtil.b64_to_utf8(regionStr);
        const params = str.split(',');
        const domain = params[0];
        const salt = params[1];
        return {
            defName: '',
            domain, salt
        }
    }

    export const NotFoundPage = () => {

        return (<_Wrap>
            <_Message>Page not found...</_Message>
        </_Wrap>);
    }
}

export default DirectManager;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #1a1e53;
    /* background-color: #c3d8dd6f; */
    /* border: solid 1px black;
    border-radius: 5px; */
    /* box-shadow: 0 2px 14px 4px #000b3a48; */
`;

const _Message = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 50px;
    margin-top: 10px;
    padding: 0 0 0 20px;
    box-sizing: border-box;
    /* background-color: #5a5e91; */
    font-size: 38px;
    font-weight: 600px;
    color: white;
    /* font-style: italic; */
    /* background-color: #c3d8dd6f; */
    /* border: solid 1px black;
    border-radius: 5px; */
    /* box-shadow: 0 2px 14px 4px #000b3a48; */
`;

const _AppFrame = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #0c0077;
`;