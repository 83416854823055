import React from "react";
import NodeEntry from "../develop/function/editor/ui/nodeEntry";
import ModelElementUtil from "../develop/function/util/modelElementUtil";
import ModelUtil from "../develop/function/util/modelUtil";
import CompuseManager from "./compuseManager";
import ReaderUtil from "./readerUtil";

namespace AppEntry {

    export const Component = React.memo((props: {
        appCache: ReaderUtil.GlobalAppCache;
        getElementsJsxRec: (elements: ModelUtil.WrapElement[], dynamicProps: ReaderUtil.DynamicProps) => JSX.Element[];
        entryData: ModelUtil.WrapElement;
        dispStackTrace: (element: ModelUtil.WrapElement, error: unknown) => void;
    }) => {
        const view = props.entryData.data as NodeEntry.Data;

        try {
            if (view.compId == undefined) throw new Error('エントリーポイントのコンポーネントが未設定です。');
        } catch (err) {
            props.dispStackTrace(props.entryData, err);
            return <></>;
        }
        return (

            <CompuseManager.Component
                appCache={props.appCache}
                getElementsJsxRec={props.getElementsJsxRec}
                data={{ compId: view.compId, props: [] }}
            />
        );
    })
};

export default AppEntry;