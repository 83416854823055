import { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import { initialStore, Store } from '../../redux/store/store';
import { HashRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Dispatcher } from '../../redux/dispatcher/dispatcher';
import PCRoot from './pCRoot';
import lf from 'lovefield';
import DirectManager from '../direct/directManager';
import ServerUtil from '../../../common/serverUtil';

type GlobalContextType = {
    store: Store;
    setStore: React.Dispatch<React.SetStateAction<Store>>;
    dispatcher: Dispatcher;
}

export const GlobalContext = createContext({} as GlobalContextType);

const SystemEntry = () => {

    return (
        <HashRouter basename={process.env.PUBLIC_URL}>
            <RouterManage />
        </HashRouter>
    );
}

export default SystemEntry;

const RouterManage = () => {

    const [store, setStore] = useState(initialStore);
    const dispatcher = new Dispatcher(store, setStore);

    const query = new URLSearchParams(useLocation().search);

    return (
        <GlobalContext.Provider value={{ store, setStore, dispatcher }}>
            <Switch>
                <Route path="/" exact>
                    <PCRoot />
                </Route>
                {/* <Route path="/sp" exact >
                    <SPRoot />
                </Route> */}
                <Route path={`/${ServerUtil.URL_ROUTER_PROJECT}`} exact >
                    <DirectManager.ProjectComponnet query={query} />
                </Route>
                <Route path={`/${ServerUtil.URL_ROUTER_LAUNCH}`} exact >
                    <DirectManager.AppComponnet query={query} />
                </Route>
                <Route path="*" exact >
                    <DirectManager.NotFoundPage />
                </Route>
            </Switch>
        </GlobalContext.Provider>
    );
}