import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { GlobalContext } from "../entry/systemEntry";
import FormUtil from "../../../common/component/form/formUtiil";
import ServerUtil from "../../../common/serverUtil";
import CanvasUtil from "../../../common/canvas/canvasUtil";
import ConfirmDialog from "../../../common/component/dialog/confirmDialog";
import ValidateUtil from "../../../common/component/form/validateUtil";
import AccountFrame from "./accountFrame";
import LocalStrageUtil from "../../util/localStrageUtil";

const LoginCreateAccount = () => {
    const { store, setStore } = useContext(GlobalContext);

    const [isRegist, setRegist] = useState(false);

    const [userId, setUserId] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });
    const [password, setPassword] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });
    const [passwordConfirm, setPasswordConfirm] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });
    const [messageList, setMessageList] = useState<string[]>([]);

    const [userIdList, setUserIdList] = useState<string[]>([]);
    const region = LocalStrageUtil.getActiveRegionDef();

    const createAuthText = () => {
        // const list = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        // const list = '0123456789abcdefghijklmnopqrstuvwxyz';
        const list = '0123456789';
        let text = '';
        for (let i = 0; i < 6; i++) {
            text += list.charAt(Math.random() * list.length);
        }
        return text;
    };

    /** 既存のユーザIDリストを格納 */
    useEffect(() => {
        ServerUtil.findUserIdList(region).then(list => {
            setUserIdList(list);
        });

        // fetch('https://api.ipify.org/?format=json').then(res => {
        //     res.json().then(data => {
        //         alert(data['ip']);
        //     });
        // });
    }, []);

    const [authCollation, setAuthCollation] = useState<string>(createAuthText());
    const [authInput, setAuthInput] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });

    /** キャッシュした認証画像 */
    const authPicture = useMemo(() => {
        return CanvasUtil.getAuthTextURL(authCollation);

    }, [authCollation]);

    useEffect(() => {
        const list: string[] = [];
        if (userId.errors.length > 0) {
            switch (userId.errors[0]) {
                case 'required': list.push('[user id] is required.'); break;
                case 'length': list.push('[user id] set a value between 4 and 20.'); break;
                case 'value': list.push('[user id] accepts only half-width alphanumeric characters.'); break;
                case 'relation': list.push('[user id] already in use.'); break;
            }
        }
        if (password.errors.length > 0) {
            switch (password.errors[0]) {
                case 'required': list.push('[password] is required.'); break;
                case 'length': list.push('[password] set a value between 4 and 20.'); break;
                case 'relation': list.push('[password] does not match confirm.'); break;
            }
        }
        if (passwordConfirm.errors.length > 0) {
            switch (passwordConfirm.errors[0]) {
                case 'length': list.push('[password *] set a value between 4 and 20.'); break;
            }
        }
        setMessageList(list);
    }, [userId, password]);

    const registAction = () => {

        (async () => {
            let response = await ServerUtil.sendQueryRequestToAPI('select', `SELECT count(*) as cnt FROM user_tbl where id = '${userId}'`, region);
            let results = await response.json();
            console.log(JSON.stringify(results[0].cnt));
            const isExist = results[0].cnt > 0;
            if (isExist) {
                setMessageList(['That user id has already been recorded.']);
            } else {
                const nextSeq = await ServerUtil.getNextUserSeq(region);
                console.log(nextSeq);
                await ServerUtil.sendQueryRequestToAPI('update', `INSERT INTO user_tbl(seq, id, password) VALUES(${nextSeq}, '${userId.value}', '${password.value}')`, region);
                store.user = {
                    seq: nextSeq,
                    id: userId.value,
                    password: password.value,
                    email: '',
                    devmdList: []
                };
                // store.cloud = {
                //     projects: []
                // };
                store.system.dialog = (<ConfirmDialog.Component
                    message='You have successfully created an account!'
                    buttons={[
                        {
                            label: 'OK',
                            callback: () => {
                                store.system.dialog = (<AccountFrame />);
                                setStore({...store});
                            }
                        }
                    ]}
                />);
                setStore({...store});
            }
        })();
    }

    const isLoginInfoOK = () => {
        return userId.errors.length === 0 && password.errors.length === 0 && passwordConfirm.errors.length === 0;
    }
    const isAuthOK = () => {
        return authInput.errors.length === 0 && authCollation === authInput.value;
    }

    return (<>

        <_Frame isVisible={!isRegist}>
            <FormUtil.BorderPanel
                title="Login information"
                innerJsx={<>
                    <FormUtil.Message
                        message={'Please, enter User ID/Pass (Confirm).'}
                    />
                    <FormUtil.InputTextFormBak
                        placeholder={'user id'}
                        checkable={userId}
                        setCheckable={setUserId}
                        validates={[
                            {
                                checker: (value) => ValidateUtil.checkRequired(value),
                                errorType: "required"
                            },
                            {
                                checker: (value) => ValidateUtil.checkStringLength(value, 4, 20),
                                errorType: "length"
                            },
                            {
                                checker: (value) => ValidateUtil.checkAsciiChars(value),
                                errorType: "value"
                            },
                            {
                                checker: (value) => !userIdList.includes(value),
                                errorType: "relation"
                            },
                        ]}
                        width={300}
                        isCenter
                    />
                    <FormUtil.InputTextFormBak
                        placeholder={'password'}
                        isPassword
                        checkable={password}
                        setCheckable={setPassword}
                        validates={[
                            {
                                checker: (value) => ValidateUtil.checkRequired(value),
                                errorType: "required"
                            },
                            {
                                checker: (value) => ValidateUtil.checkStringLength(value, 4, 20),
                                errorType: "length"
                            },
                            {
                                checker: (value) => value === passwordConfirm.value,
                                errorType: "relation"
                            }
                        ]}
                        dependency={[passwordConfirm]}
                        width={300}
                        isCenter
                    />
                    <FormUtil.InputTextFormBak
                        placeholder={'password*'}
                        isPassword
                        checkable={passwordConfirm}
                        setCheckable={setPasswordConfirm}
                        validates={[
                            {
                                checker: (value) => ValidateUtil.checkRequired(value),
                                errorType: "required"
                            },
                            {
                                checker: (value) => ValidateUtil.checkStringLength(value, 4, 20),
                                errorType: "length"
                            },
                            {
                                checker: (value) => value === password.value,
                                errorType: "relation"
                            }
                        ]}
                        dependency={[password]}
                        width={300}
                        isCenter
                    />
                    <FormUtil.InfoFrame textRecords={messageList} />
                    <FormUtil.ButtonRecord
                        buttons={[
                            {
                                label: 'Enter', isEnable: isLoginInfoOK(), callback: () => {
                                    setRegist(true);
                                }
                            },
                        ]}
                    />
                </>}
            />
        </_Frame>
        <_Frame isVisible={isRegist}>
            <FormUtil.BorderPanel
                title="Proof not a robot"
                innerJsx={<>
                    <FormUtil.Message
                        message={'Please, input the same 6 number as the picture.'}
                    />
                    <_AuthText src={authPicture} />
                    <FormUtil.ButtonRecord
                        buttons={[
                            {
                                label: 'Change', callback: () => {
                                    setAuthCollation(createAuthText());
                                    setAuthInput({ value: '', errors: [] });
                                }
                            },
                        ]}
                    />
                    {/* 照合する文字列 */}
                    <FormUtil.InputTextFormBak
                        placeholder={'6 numbers'}
                        checkable={authInput}
                        setCheckable={setAuthInput}
                        width={300}
                        isCenter
                    />
                    <FormUtil.ButtonRecord
                        buttons={[
                            { label: 'Regist', isEnable: isAuthOK(), callback: registAction },
                        ]}
                    />
                </>}
            />
            <FormUtil.MessageLink
                message="Return to login information."
                clickAction={() => {
                    setRegist(false);
                }}
            />
        </_Frame>
    </>);
};

export default LoginCreateAccount;

const _Frame = styled.div<{
    isVisible: boolean;
}>`
    display: ${props => props.isVisible ? 'block' : 'none'};
`;

const _AuthText = styled.img<{
}>`
    display: block;
    text-align: center;
    margin: 4px auto;
    background-color: #f7f0f0;
    border: 1px solid #888888;
    box-sizing: border-box;
`;