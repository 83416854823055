import assert, { throws } from "assert";
import ModelElementUtil from "../develop/function/util/modelElementUtil";
import ModelUtil from "../develop/function/util/modelUtil";
import DataUtil from "../../../common/dataUtil";
import VariableChooser from "../develop/function/editor/proc/variableChooser";
import NodeFocus from "../develop/function/editor/proc/focus/nodeFocus";
import NodeStlarg from "../develop/function/editor/decrare/nodeStlarg";
import StyleChooser from "../develop/function/editor/decrare/styleChooser";
import TagUtil from "../develop/function/editor/ui/tag/tagUtil";
import NodeAssign from "../develop/function/editor/proc/focus/nodeAssign";
import NodeArrayAdd from "../develop/function/editor/proc/focus/nodeArrayAdd";
import NodeArrayDel from "../develop/function/editor/proc/focus/nodeArrayDel";
import NodeStyle from "../develop/function/editor/decrare/nodeStyle";
import PrefixUtil from "../develop/function/util/prefixUtil";
import AssertUtil from "../../../common/assertUtil";
import StyleFormulaDefiner from "../develop/function/editor/decrare/style/styleFormulaDefiner";
import NodeCase from "../develop/function/editor/condition/nodeCase";
import NodeWhen from "../develop/function/editor/condition/nodeWhen";
import NodeAccept from "../develop/function/editor/condition/nodeAccept";
import NodeField from "../develop/function/editor/var/nodeField";
import NodeFunction from "../develop/function/editor/var/func/nodeFunction";
import NodeExecute from "../develop/function/editor/proc/nodeExecute";
import NodeFetch from "../develop/function/editor/proc/nodeFetch";
import NodeIterate from "../develop/function/editor/nodeIterate";
import NodeAssignMatch from "../develop/function/editor/proc/focus/nodeAssignMatch";
import NodeArrayCat from "../develop/function/editor/proc/focus/nodeArrayCat";
import NodeArrayEff from "../develop/function/editor/proc/focus/nodeArrayEff";
import NodeThen from "../develop/function/editor/proc/nodeThen";
import SystemFunctionUtil from "./systemFunctionUtil";
import NodeReturn from "../develop/function/editor/var/func/nodeReturn";
import ErrorFrame from "./errorFrame";
import NodeNative from "../develop/function/editor/var/func/nodeNative";
import NodeInvalidate from "../develop/function/editor/var/func/nodeInvalidate";
import NodeEffect from "../develop/function/editor/linkage/nodeEffect";
import NodePromise from "../develop/function/editor/var/func/nodePromise";
import NodeCompdef from "../develop/function/editor/decrare/nodeCompdef";
import NodeStruct from "../develop/function/editor/nodeStruct";
import NodeSwitch from "../develop/function/editor/condition/nodeSwitch";
import NodeRange from "../develop/function/editor/condition/nodeRange";
import NodeBlock from "../develop/function/editor/var/nodeBlock";
import NodeLog from "../develop/function/editor/proc/nodeLog";

namespace ReaderUtil {

    export type ReaderState = {
        globals: FieldObject[] | null;
        error: ErrorFrame.Props | null;
    }

    export type FieldObject = {
        field: ModelUtil.NodeModelField;
        value: any;
    }

    export type IterateProps = {
        id: string;
        self: number;
        max: number;
    }

    export type StructObject = {
        id: string;
        fields: NodeField.MemberData[];
    }

    export type ActionObject = {
        key: string;
        callback: () => void;
    }

    export type FuncObject = {
        id: string;
        funcargs: ModelUtil.NodeModelField[];
        procItems: ModelUtil.WrapElement[];
        callback: Function;
    }

    export type EffectObject = {
        depItems: any[];
        callback: Function;
    }
    export type MemoObject = {
        depItems: any[];
    }

    export type StyleObject = {
        id: string;
        src: string;
        fmls: StyleFormulaDefiner.Record[];
        args: NodeStlarg.Data[];
    }

    export type PartialSet = {
        id: string;
        invalidate: () => void;
    }

    export const convertStructDataToObject = (baseDataList: NodeStruct.Data[]): StructObject[] => {
        return baseDataList.map(data => {
            return { id: data.id, fields: data.fields.map(wrap => wrap.data as ModelUtil.NodeModelField) };
        });
    }

    /**
      * DType型の構成に従って初期オブジェクト（JSON）を生成する。
      * @param field モデル型
      * @param structs データタイプリスト
      * @returns モデル・初期オブジェクト
      */
    export const buildInitialStructureObject = (
        field: ModelUtil.NodeModelField,
        structs: StructObject[]
    ): ReaderUtil.FieldObject => {
        const getValueRec = (field: ModelUtil.NodeModelField, useDefval: boolean): any => {
            if (field.array > 0) return '[]';
            if (field.dataType === 'struct') {
                const model = structs.find(m => m.id === field.structId);
                assert(model != undefined, 'model is null.');
                const list = model.fields.map(f => {
                    if (!(['number', 'boolean', 'struct'] as ModelUtil.DataType[]).includes(f.dataType)) {
                        return `"${f.id}": "${getValueRec(f, true)}"`;
                    } else {
                        return `"${f.id}": ${getValueRec(f, true)}`;
                    }
                });
                return `{${list.join(',')}}`;
            }
            else {
                // デフォルト値が設定されている場合のみ利用する
                const memberData = field as NodeField.MemberData;
                if (useDefval && memberData.defval != undefined) {
                    let defval: any = memberData.defval;
                    if ((['number', 'boolean'] as ModelUtil.DataType[]).includes(field.dataType)) {
                        defval = DataUtil.applyFormula(defval);
                    }
                    return defval;
                }
                return NodeField.getDefaultValue(field.dataType);
            }
        }

        let value = getValueRec(field, false);
        if (field.array >= 1 || field.dataType === 'struct') {
            value = JSON.parse(value);
        }
        return { field: field, value };
    }

    /** プロシージャを適用する */
    export const applyProcedure = (items: ModelUtil.WrapElement[], appCache: GlobalAppCache) => {
        // console.log('applyProcedure');
        const dynamics = appCache.dynamics;
        // const dynamics = cloneDynamicProps(appCache.dynamics);
        // appCache = {...appCache, dynamics};
        // dynamics.variables = dynamics.variables.slice();
        const variables = dynamics.variables;
        const buildInitialStructureObject = appCache.globalFixedProps.buildInitialStructureObject;

        let ret: any = undefined;

        /** 自身の処理を再帰的に行う */
        const rec = (items: ModelUtil.WrapElement[]) => applyProcedure(items, appCache);

        items.forEach(item => {
            // console.log(item);

            // 非活性配下は処理しない
            if (item.disabled) return 1;

            switch (item.type) {
                case 'block': {
                    const data = item.data as NodeBlock.Data;
                    rec(data.items);
                } break;
                case 'compdef': {
                    const data = item.data as NodeCompdef.Data;
                    // const dynamics = cloneDynamicProps(appCache.dynamics);
                    // const dynamics = appCache.dynamics;
                    // // const tempCompStates = appCache.dynamics.componentStates;
                    // // dynamics.componentStates = tempCompStates;
                    // const compdefMap = appCache.compdefMap;

                    // // 同じものがあれば削除
                    // const sameIndex = compdefMap.findIndex(d => d.def.id === data.id);
                    // if (sameIndex !== -1) {
                    //     compdefMap.splice(sameIndex, 1);
                    // }
                    // // 定義時点の動的キャッシュをクローン
                    // compdefMap.push({
                    //     def: data,
                    //     dynamics
                    // });

                    // 同じものがあれば削除
                    const sameIndex = dynamics.compdefs.findIndex(d => d.id === data.id);
                    if (sameIndex !== -1) {
                        dynamics.compdefs.splice(sameIndex, 1);
                    }
                    dynamics.compdefs.push(data);
                } break;
                case 'variable': {
                    const varData = item.data as NodeField.VariableData;
                    // console.log(varData.id);
                    const sameIndex = variables.findIndex(v => v.field.id === varData.id);
                    if (sameIndex !== -1) {
                        variables.splice(sameIndex, 1);
                    }
                    variables.push(buildInitialStructureObject(varData));
                    if (varData.items != undefined && varData.items.length > 0) {
                        ReaderUtil.affectOperation({
                            data: {
                                target: 'variable',
                                rootId: varData.id,
                                levelProps: [],
                                items: varData.items,
                            }, appCache
                        });
                    }
                } break;
                case 'style': {
                    const obj = ReaderUtil.getStyleObjFromData(item.data, appCache);
                    dynamics.styles.push(obj);
                } break;
                case 'accept': {
                    const accept = item.data as NodeAccept.Data;
                    // console.log(dynamicProps.iteraters);
                    // console.log(accept.condition);
                    const isAccept = ReaderUtil.isTrue(accept.condition, appCache);
                    if (isAccept) {
                        // console.log('pass');
                        rec(accept.elements);
                    }
                } break;
                case 'case': {
                    const data = item.data as NodeCase.Data;

                    deligateCaseExecute(data, rec, appCache);
                } break;
                case 'iterate': {
                    const data = item.data as NodeIterate.Data;
                    // console.log(data);
                    // let fml = data.itrCntFml;

                    // // 変数の置換
                    // fml = replaceKeySets(fml, appCache);

                    // // console.log(fml);
                    // // 式の適用
                    // fml = DataUtil.applyFormula(fml);
                    // const loopCnt = Number(fml);
                    // assert(!isNaN(loopCnt), `loopCntがNaNです。[${fml}]`);

                    const loopCnt = ReaderUtil.getFomulaAplliedValue(data.itrCntFml, appCache);

                    for (let i = 0; i < loopCnt; i++) {
                        // イテレータのコピーを取得
                        // const dynamics = cloneDynamicProps(dynamicProps);
                        // console.log(dynamicProps);
                        const cacheIteraters = dynamics.iteraters.slice();
                        const sameIndex = cacheIteraters.findIndex(i => i.id === data.id);
                        if (sameIndex !== -1) cacheIteraters.splice(sameIndex, 1);
                        cacheIteraters.push({ id: data.id, self: i, max: loopCnt });
                        dynamics.iteraters = cacheIteraters;
                        // const cloneDynamics: DynamicProps = { ...dynamicProps, iteraters: cacheIteraters };
                        // イテレータ以下の処理は、スコープ外へ干渉する必要があるためクローンしない
                        // applyProcedure(data.elements, appCache, cloneDynamics);
                        // rec(data.elements);
                        applyProcedure(data.elements, { ...appCache, dynamics });
                    }
                } break;
                case 'focus': ReaderUtil.affectOperation({ data: item.data, appCache: { ...appCache, dynamics } }); break;
                case 'func': {
                    const data = item.data as NodeFunction.Data;
                    // dynamicProps.funcs = dynamicProps.funcs
                    //     .concat(ReaderUtil.buildFunctionObjectFromData(data, appCache));
                    const funcObj = ReaderUtil.buildFunctionObjectFromData(data, appCache);
                    const sameIndex = dynamics.funcs.findIndex(f => f.id === funcObj.id);
                    if (sameIndex !== -1) {
                        // console.log(`same: [${funcObj.id}]`);
                        dynamics.funcs.splice(sameIndex, 1);
                    }
                    // console.log(funcObj);
                    dynamics.funcs.push(funcObj);
                } break;
                case 'execute': {
                    const data = item.data as NodeExecute.Data;
                    // console.log(data);
                    const callback = ReaderUtil.generateCallbackFromDispatcher({
                        appCache: appCache, dispatch: {
                            dsptId: data.eventId, args: data.args
                        }
                    });
                    callback();
                } break;
                case 'invalidate': {
                    const data = item.data as NodeInvalidate.Data;

                    appCache.dynamics.utils.partialInvalidate(data.partial);
                } break;
                case 'native': {
                    const data = item.data as NodeNative.Data;

                    const [formula, args] = getArgsFormatFromFormula(data.fml, appCache);
                    DataUtil.applyFormulaArgs(args, `(()=>{\n${formula}\n})()`);
                } break;
                case 'log': {
                    const data = item.data as NodeLog.Data;

                    const [formula, args] = getArgsFormatFromFormula(data.fml, appCache);
                    const value = DataUtil.applyFormulaArgs(args, formula);
                    switch (data.method) {
                        case 'alert': alert(value); break;
                        case 'console': console.log(value); break;
                    }
                } break;
                case 'return': {
                    const data = item.data as NodeReturn.Data;

                    if (data.fml != undefined) {
                        const [formula, args] = getArgsFormatFromFormula(data.fml, appCache);
                        ret = DataUtil.applyFormulaArgs(args, formula);
                    }
                } break;
                // エフェクト関数を実行する
                case 'effect': {
                    const data = item.data as NodeEffect.Data;
                    const effect = ReaderUtil.buildEffectFuncObjectFromData(data, appCache);
                    // dynamicProps.effects = dynamicProps.effects.concat(effect);
                    dynamics.effects.push(effect);
                } break;
                // 非同期処理を実行する
                case 'promise': {
                    const data = item.data as NodePromise.Data;
                    // const dynamics = cloneDynamicProps(appCache.dynamics);

                    const [formula, args] = getArgsFormatFromFormula(data.fml, appCache);
                    const promise: () => Promise<any> = DataUtil.applyFormulaArgs(args, `async ()=> {${formula}}`);

                    let thenProc = undefined;

                    const thenWrap = data.mngs.find(m => m.type === 'then');
                    if (thenWrap != undefined) {
                        thenProc = (res: any) => {
                            const then = thenWrap.data as NodeThen.Data;

                            // レスポンスのキャッシュの追加する
                            const resCacheData: NodeField.VariableData = {
                                id: then.id,
                                dataType: 'any',
                                array: 0,
                                isRefer: true,
                                items: []
                            }
                            const cacheObject = buildInitialStructureObject(resCacheData);
                            cacheObject.value = res;
                            // console.log(res);
                            // console.log(variables);
                            dynamics.variables.push(cacheObject);

                            // thenの内部処理を実行
                            applyProcedure(then.items, { ...appCache, dynamics });
                            // rec(then.items);
                        }
                    }

                    const catchWrap = data.mngs.find(m => m.type === 'catch');
                    let catchProc = undefined;
                    if (catchWrap != undefined) {

                        catchProc = (err: string) => {
                            const data = catchWrap.data as NodeThen.Data;

                            // レスポンスのキャッシュの追加する
                            const errCacheData: NodeField.VariableData = {
                                id: data.id,
                                dataType: 'string',
                                array: 0,
                                isRefer: true,
                                items: []
                            }
                            const cacheObject = buildInitialStructureObject(errCacheData);
                            cacheObject.value = err;
                            variables.push(cacheObject);

                            // applyProcedure(data.items, appCache);
                            rec(data.items);
                        }
                    }

                    executePromise({
                        promise,
                        buildProps: appCache,
                        wrap: item,
                        thenProc,
                        catchProc
                    });
                } break;
                case 'fetch': {
                    const data = item.data as NodeFetch.Data;

                    /** データよりリクエストオブジェクトを生成 */
                    const getRequestInit = () => {
                        const req = data.reqOpt;
                        let body = req.body != undefined ? replaceKeySets(req.body, appCache) : undefined;

                        // console.log(body);
                        return {
                            mode: req.mode,
                            method: req.method,
                            headers: {
                                Accept: req.accept,
                                'Content-Type': req.contentType,
                            },
                            body: body
                        } as RequestInit;
                    }
                    const thenProc = (res: any) => {
                        // console.log(res);

                        const thenWrap = data.mngs.find(m => m.type === 'then');
                        if (thenWrap != undefined) {
                            const then = thenWrap.data as NodeThen.Data;

                            // レスポンスのキャッシュの追加する
                            const resCacheData: NodeField.VariableData = {
                                id: then.id,
                                dataType: 'any',
                                array: 0,
                                isRefer: true,
                                items: []
                            }
                            const cacheObject = buildInitialStructureObject(resCacheData);
                            cacheObject.value = res;
                            variables.push(cacheObject);

                            // thenの内部処理を実行
                            // applyProcedure(then.items, appCache);
                            rec(then.items);
                        }
                    }
                    const catchWrap = data.mngs.find(m => m.type === 'catch');
                    const catchProc = (err: string) => {
                        if (catchWrap != undefined) {
                            const data = catchWrap.data as NodeThen.Data;

                            // レスポンスのキャッシュの追加する
                            const errCacheData: NodeField.VariableData = {
                                id: data.id,
                                dataType: 'string',
                                array: 0,
                                isRefer: true,
                                items: []
                            }
                            const cacheObject = buildInitialStructureObject(errCacheData);
                            cacheObject.value = err;
                            variables.push(cacheObject);

                            // applyProcedure(data.items, appCache);
                            rec(data.items);
                        } else {
                            appCache.dispStackTrace(item, err);
                        }
                    }
                    const req = getRequestInit();
                    // console.log(req);
                    fetch(data.url, req)
                        .then(value => {
                            try {
                                thenProc(value);
                            } catch (err) {
                                appCache.dispStackTrace(item, err);
                            }
                        })
                        .catch(err => {
                            catchProc(err);
                        });
                } break;
                default: throw new Error(`item{${item.type}}は想定されていない。（未実装）`);
            }
        });

        return ret;
    }

    export const deligateCaseExecute = (
        data: NodeCase.Data,
        recCallback: (items: ModelUtil.WrapElement[]) => void,
        appCache: GlobalAppCache
    ) => {
        switch (data.method) {
            case 'bool': {
                const condition = data.condition;
                // console.log(buildProps.dynamicProps.caches);
                assert(condition != undefined, 'メソッドがboolの場合、conditionがundefinedであってはならない。');
                const isAccept = ReaderUtil.isTrue(condition, appCache);
                // console.log(isAccept);
                const whenTrue = data.items[0].data as ModelUtil.NodeBoolData;
                const whenFalse = data.items[1].data as ModelUtil.NodeBoolData;
                assert(whenTrue.bool === true && whenFalse.bool === false, 'case->boolに紐づいている要素が不正。')
                if (isAccept) {
                    // console.log('true');
                    recCallback(whenTrue.elements);
                } else {
                    // console.log('false');
                    recCallback(whenFalse.elements);
                }
            } break;
            case 'switch': {
                const target = data.target;
                // console.log(buildProps.dynamicProps.caches);
                assert(target != undefined, 'メソッドがswitchの場合、targetIdがundefinedであってはならない。');

                const targetValue = getFomulaAplliedValue(target, appCache);
                const switchItems = data.items.map(i => i.data as NodeSwitch.Data);

                switchItems.some(switchItem => {
                    const caseValue = getFomulaAplliedValue(switchItem.fml, appCache);
                    if (targetValue === caseValue) {
                        recCallback(switchItem.elements);
                        // 条件に合致した時点で他の条件は見ない
                        return 1;
                    }
                });
            } break;
            case 'when': {
                const whenItems = data.items.map(i => i.data as NodeWhen.Data);

                whenItems.some(whenItem => {
                    const isAccept = ReaderUtil.isTrue(whenItem.condition, appCache);
                    // console.log(isAccept);
                    if (isAccept) {
                        recCallback(whenItem.elements);
                        // 条件に合致した時点で他の条件は見ない
                        return 1;
                    }
                });
            } break;
            // case 'range': {
            //     const target = data.target;
            //     // console.log(buildProps.dynamicProps.caches);
            //     assert(target != undefined, 'メソッドがswitchの場合、targetIdがundefinedであってはならない。');

            //     const targetValue = getFomulaAplliedValue(target, appCache);
            //     const rangeItems = data.items.map(i => i.data as NodeRange.Data);

            //     let lastValue = 0
            //     rangeItems.some(rangeItem => {
            //         const breakValue = getFomulaAplliedValue(rangeItem.fml, appCache);
            //         if (targetValue >= lastValue && targetValue < breakValue) {
            //             recCallback(rangeItem.elements);
            //             // 条件に合致した時点で他の条件は見ない
            //             return 1;
            //         }
            //         lastValue = breakValue;
            //     });
            // } break;
        }
    }

    export const updateInvalidateCallback = (dynamics: DynamicProps) => {

        /**
         * ローカルID配下を部分再描画
         */
        const partialInvalidate = (partialSets: ReaderUtil.PartialSet[], partialId?: string) => {
            // 空白はundefinedとして処理する
            partialId = partialId === '' ? undefined : partialId;
            if (partialId == undefined) {
                // console.log('partialIdが指定されなかった');
                dynamics.utils.globalInvalidate();
                return;
            }
            // console.log(`パーシャルID：${partialId}`);

            const partial = partialSets.find(lp => lp.id === partialId);

            assert(partial != undefined,
                `[存在しないpartialId(${partialId})が指定された。利用可能なpartialId[${partialSets.
                    map(l => l.id).join(', ')}]`
            );
            // console.log(partial);
            // console.log('ローカル処理');
            partial.invalidate();
        }
        // console.log('関数更新');
        // 再描画関数をローカル対応の関数で上書き
        dynamics.utils.partialInvalidate = (partialId?: string) => partialInvalidate(dynamics.partialSets, partialId);
    }

    const executePromise = (args: {
        promise: () => Promise<any>,
        thenProc?: (res: any) => void,
        catchProc?: (err: any) => void,
        buildProps: GlobalAppCache,
        wrap: ModelUtil.WrapElement
    }
    ) => {
        // console.log(typeof args.promise);
        try {
            args.promise()
                .then(value => {
                    if (args.thenProc != undefined) args.thenProc(value);
                })
                .catch(err => {
                    if (args.catchProc != undefined) args.catchProc(err);
                    else args.buildProps.dispStackTrace(args.wrap, err);
                });
        } catch (e) {
            args.buildProps.dispStackTrace(args.wrap, e);
        }
    }

    /**
     * 定義を元に関数オブジェクトを生成する
     * @param funcData 
     * @param appCache 
     * @returns イベントオブジェクト
     */
    export const buildFunctionObjectFromData = (funcData: NodeFunction.Data, appCache: GlobalAppCache): FuncObject => {
        const argsData = ModelElementUtil.getInnerWrapFixed({ type: 'func', data: funcData }, 'args').data as ModelUtil.ArgsData;
        const procData = ModelElementUtil.getInnerWrapFixed({ type: 'func', data: funcData }, 'proc').data as ModelUtil.NodeProcedure;

        const funcargs = argsData.args.map(arg => arg.data as ModelUtil.NodeModelField);
        const callback = (...args: any) => {
            const cloneDynamics = cloneDynamicProps(appCache.dynamics);
            funcargs.forEach((a, i) => {
                const argObj: FieldObject = {
                    field: a,
                    value: args[i]
                };
                // console.log(funcData.id);
                // console.log(args);
                cloneDynamics.funcargs.push(argObj);
            });
            return applyProcedure(procData.items, { ...appCache, dynamics: cloneDynamics });
        }
        return {
            id: funcData.id,
            funcargs,
            procItems: procData.items,
            callback
        }
    }

    /**
     * 定義を元にエフェクト関数オブジェクトを生成する
     * @param effectData 
     * @param appCache 
     * @returns イベントオブジェクト
     */
    export const buildEffectFuncObjectFromData = (effectData: NodeEffect.Data, appCache: GlobalAppCache): EffectObject => {
        const procData = ModelElementUtil.getInnerWrapFixed({ type: 'effect', data: effectData }, 'proc').data as ModelUtil.NodeProcedure;

        let depItems = [];
        if (effectData.depFmls != undefined) {
            depItems = effectData.depFmls.map(dep => {
                const [formula, args] = getArgsFormatFromFormula(dep, appCache);
                return DataUtil.applyFormulaArgs(args, formula);
            });
        }
        return {
            depItems,
            callback: () => {
                // return applyProcedure(procData.items, buildProps);
                applyProcedure(procData.items, appCache);
            }
        }
    }

    export type GlobalFixedProps = {
        states: ModelUtil.NodeModelField[];
        launchArgs: FieldObject[];
        structs: StructObject[];
        buildInitialStructureObject: (field: ModelUtil.NodeModelField) => ReaderUtil.FieldObject;
    };

    /**
     * コンポーネント定義と定義階層のキャッシュのクローンを管理するプロパティ
     */
    export type compdefProps = {
        /** 定義 */
        def: NodeCompdef.Data;
        /** キャッシュのクローン */
        dynamics: DynamicProps;
    }

    // export const getInitialDynamicProps = (): DynamicProps => ({
    //     iteraters: [], variables: [], temps: [], funcargs: [], funcs: [], effects: [],
    //     prpflds: [], prpclbks: [], styles: [], refs: [],
    //     localPoints: [],
    //     componentStates: []
    // });

    /**
     * アプリで利用するキャッシュを管理するプロパティ群
     */
    export type GlobalAppCache = {
        dynamics: DynamicProps;
        appStates: FieldObject[];
        compdefMap: ReaderUtil.compdefProps[];
        globalFixedProps: GlobalFixedProps;
        dispStackTrace: (element: ModelUtil.WrapElement, e: unknown) => void;
        isError: () => boolean;
    }

    /** 
     * 動的プロパティ群
     */
    export type DynamicProps = {
        iteraters: IterateProps[];
        variables: FieldObject[];
        temps: FieldObject[];
        funcargs: FieldObject[];
        funcs: FuncObject[];
        effects: EffectObject[];
        prpflds: FieldObject[];
        prpclbks: FuncObject[];
        styles: StyleObject[];
        refs: DataUtil.KeyObject[];
        partialSets: PartialSet[];
        compdefs: NodeCompdef.Data[];

        componentStates: FieldObject[];

        utils: Utils;
        belengComponent: string;
    }

    type Utils = {
        globalInvalidate: () => void;
        partialInvalidate: (locaId?: string) => void;
        transition: (appId: string, localArgs?: any) => void;
    }

    export const cloneDynamicProps = (src: DynamicProps): DynamicProps => {
        return {
            iteraters: src.iteraters.slice(),
            variables: src.variables.slice(),
            temps: src.variables.slice(),
            funcargs: src.variables.slice(),
            funcs: src.funcs.slice(),
            effects: src.effects.slice(),
            prpflds: src.prpflds.slice(),
            prpclbks: src.prpclbks.slice(),
            styles: src.styles.slice(),
            refs: src.refs.slice(),
            partialSets: src.partialSets.slice(),
            compdefs: src.compdefs.slice(),

            componentStates: src.componentStates,

            utils: { ...src.utils },
            belengComponent: src.belengComponent
        }
    }

    /**
     * アプリケーションで利用するキャッシュ群
     */
    export type CommonAccessor = {
        globalStates: object;
        globalFixedProps: GlobalFixedProps;
        /** コンポーネントの再描画をする */
        invalidate: (partialId?: string) => void;
        /** アプリを停止しスタックとレースを表示する */
        dispStackTrace: (element: ModelUtil.WrapElement, e: unknown) => void;
    }

    export type ReplaceProps = {
        strKeySets: DataUtil.KeyValue[];
        numKeySets: DataUtil.KeyValue[];
    }

    export const getFomulaAplliedValue = (base: string, appCache: GlobalAppCache) => {
        const [formula, args] = getArgsFormatFromFormula(base, appCache);
        return DataUtil.applyFormulaArgs(args, formula);
    }

    export const getArgsFormatFromFormula = (base: string, appCache: GlobalAppCache): [string, any[]] => {
        const varKeys = base.match(DataUtil.VALUE_REG_EX);

        const args: any[] = [];
        let formula = base;
        if (varKeys != null) {
            // 重複削除
            const varKeySet = Array.from(new Set(varKeys));
            varKeySet.some((str, i) => {
                const apply = (value: any) => {
                    args.push(value);
                    formula = formula.replaceAll(str, `__arg_${i}`);
                };
                // const extract = /\$\{(.*?)\}/;
                const key = str.match(DataUtil.VALUE_REG_EX_INNER);
                assert(key != null, 'keyはnullであってはならない。');
                const props = key[1].split('.');
                const prefix = props[0];
                const addr = props.slice(1, props.length);
                const varId = addr[0];

                const getTargetObjects = () => {
                    const dynamics = appCache.dynamics;
                    const states = appCache.appStates.concat(appCache.dynamics.componentStates);
                    const variables = dynamics.variables.concat(dynamics.prpflds, dynamics.funcargs);
                    switch (prefix) {
                        case PrefixUtil.STATE: return states;
                        case PrefixUtil.VARIABLE: return variables;
                        case PrefixUtil.LAUNCH_ARGUMENT: return appCache.globalFixedProps.launchArgs;
                        case PrefixUtil.TEMP: return appCache.dynamics.temps;
                        // case PrefixUtil.ARGUMENT: return appCache.dynamics.funcargs;
                        // case PrefixUtil.PRPFLD: return appCache.dynamics.prpflds;
                        case PrefixUtil.ITERATE: return appCache.dynamics.iteraters;
                        case PrefixUtil.SYSTEM: return (name: SystemFunctionUtil.DefName) => SystemFunctionUtil.getMappedFuncValue(name, appCache);
                        case PrefixUtil.FUNCTION: return appCache.dynamics.funcs;
                        case PrefixUtil.REF: return appCache.dynamics.refs;
                    }
                    throw new Error(`prefixが何にも該当しない[${prefix}]`);
                }
                const targetObjects = getTargetObjects();

                switch (prefix) {
                    case PrefixUtil.STATE:
                    case PrefixUtil.VARIABLE:
                    case PrefixUtil.LAUNCH_ARGUMENT:
                        // case PrefixUtil.TEMP:
                        // case PrefixUtil.ARGUMENT:
                        // case PrefixUtil.PRPFLD: 
                        {
                            const list = targetObjects as FieldObject[];
                            const obj = list.find(o => o.field.id === varId);
                            assert(obj != undefined, `varId:[${varId}]が見つからない。[${list.map(l => l.field.id)}]`);
                            let isError = false;
                            let value = (obj as FieldObject).value;
                            if (addr.length >= 2) {
                                addr.slice(1, addr.length).some(name => {
                                    value = value[name];
                                    if (value == undefined) {
                                        isError = true;
                                        return 1;
                                    }
                                });
                            }
                            if (!isError) {
                                apply(value);
                            } else throw new Error(`key[${key}]をオブジェクトに変換できなかった。`);
                        } break;
                    case PrefixUtil.REF: {
                        const list = targetObjects as DataUtil.KeyObject[];
                        const obj = list.find(o => o.key === varId);
                        assert(obj != undefined, `varId:[${varId}]が見つからない。[${list.map(l => l.key)}]`);
                        let value = (obj as DataUtil.KeyObject).obj;
                        apply(value);
                    } break;
                    case PrefixUtil.ITERATE: {
                        const obj = (targetObjects as IterateProps[]).find(o => o.id === varId);
                        if (obj == undefined) return str;
                        if (addr.length === 2) {
                            const listPropName = addr[1];
                            switch (listPropName) {
                                case PrefixUtil.LIST_LEN: {
                                    apply(obj.max);
                                } break;
                                case PrefixUtil.LIST_CUR: {
                                    apply(obj.self);
                                } break;
                            }
                        }
                    } break;
                    case PrefixUtil.FUNCTION: {
                        const list = (targetObjects as FuncObject[]);
                        const func = list.find(o => o.id === varId);
                        assert(func != undefined, `${key}が見つからない。[${list.map(l => l.id)}]`);
                        const callback = func.callback;//getCallbackObjectArgs({ appCache, dispatch: func });
                        apply(callback);
                    } break;
                    case PrefixUtil.SYSTEM: {
                        const obj = targetObjects as ((name: SystemFunctionUtil.DefName) => string);
                        apply(obj(varId as SystemFunctionUtil.DefName));
                    } break;
                }
            });
        }
        return [formula, args];
    }

    export const replaceKeySets = (base: string, appCache: GlobalAppCache): string => {
        const varKeys = base.match(DataUtil.VALUE_REG_EX);

        if (varKeys == null) return base;
        // console.log(varKeys);
        let ret = base;
        varKeys.some(str => {
            // console.log(str);
            // const extract = /\$\{(.*?)\}/;
            const key = str.match(DataUtil.VALUE_REG_EX_INNER);
            // console.log(key);
            assert(key != null, 'keyはnullであってはならない。');
            const props = key[1].split('.');
            const prefix = props[0];
            const addr = props.slice(1, props.length);
            const varId = addr[0];

            const getTargetObjects = () => {
                const states = appCache.appStates.concat(appCache.dynamics.componentStates);
                const variables = appCache.dynamics.variables.concat(appCache.dynamics.prpflds, appCache.dynamics.funcargs);
                switch (prefix) {
                    case PrefixUtil.STATE: return states;
                    case PrefixUtil.VARIABLE: return variables;
                    case PrefixUtil.LAUNCH_ARGUMENT: return appCache.globalFixedProps.launchArgs;
                    // case PrefixUtil.TEMP: return appCache.dynamics.temps;
                    // case PrefixUtil.ARGUMENT: return appCache.dynamics.funcargs;
                    // case PrefixUtil.PRPFLD: return appCache.dynamics.prpflds;
                    case PrefixUtil.ITERATE: return appCache.dynamics.iteraters;
                    case PrefixUtil.SYSTEM: return SystemFunctionUtil.getMappedFuncValue;
                }
                throw new Error(`prefixが何にも該当しない[${prefix}]`);
            }
            const targetObjects = getTargetObjects();
            switch (prefix) {
                case PrefixUtil.STATE:
                case PrefixUtil.VARIABLE:
                case PrefixUtil.LAUNCH_ARGUMENT:
                    // case PrefixUtil.TEMP:
                    // case PrefixUtil.ARGUMENT:
                    // case PrefixUtil.PRPFLD: 
                    {
                        const obj = (targetObjects as FieldObject[]).find(o => o.field.id === varId);
                        if (obj == undefined) return str;
                        let isError = false;
                        let value = (obj as FieldObject).value;
                        if (addr.length >= 2) {
                            addr.slice(1, addr.length).some(name => {
                                value = value[name];
                                if (value == undefined) {
                                    isError = true;
                                    return 1;
                                }
                            });
                        }
                        if (!isError) {
                            // console.log(`${addr}: [${value}]`);
                            ret = ret.replace(str, value);
                        } else {
                            throw new Error(`addrが変数リストに存在しない。addr:[${addr}]`);
                        }
                    } break;
                case PrefixUtil.ITERATE: {
                    const obj = (targetObjects as IterateProps[]).find(o => o.id === varId);
                    if (obj == undefined) return str;
                    if (addr.length === 2) {
                        const listPropName = addr[1];
                        switch (listPropName) {
                            case PrefixUtil.LIST_LEN: {
                                ret = ret.replace(str, obj.max.toString());
                                // console.log(`[${str}]を[${obj.max.toString()}]に置換しました。`);
                            } break;
                            case PrefixUtil.LIST_CUR: {
                                ret = ret.replace(str, obj.self.toString());
                                // console.log(`[${str}]を[${obj.self.toString()}]に置換しました。`);
                            } break;
                        }
                    }
                } break;
                case PrefixUtil.SYSTEM: {
                    const obj = targetObjects as ((name: SystemFunctionUtil.DefName) => string);
                    ret = obj(varId as SystemFunctionUtil.DefName);
                } break;
            }
        });
        // if (varKeys.length > 0 && varKeys[0].indexOf('c.insValuesStr') !== -1) {
        //     console.log(ret);
        //     console.log(varKeys);
        // }
        return ret;
    }

    export type KeyFieldObject = {
        key: string;
        fieldObject: FieldObject;
    }

    export type EventArgument = {
        field: ModelUtil.NodeModelField;
        value: string;
    }

    export const getChoosedValue = (chooser: VariableChooser.Chooser, appCache: GlobalAppCache): any => {
        const globalStates = appCache.appStates;
        const variables = appCache.dynamics.variables;
        const prpflds = appCache.dynamics.prpflds;
        const funcargs = appCache.dynamics.funcargs;
        const structs = appCache.globalFixedProps.structs;
        const dynamicProps = appCache.dynamics;

        const getTargets = () => {
            switch (chooser.target) {
                case 'state': return globalStates.concat(dynamicProps.componentStates);
                case 'variable': return variables;
                // case 'comparg': return prpflds;
                // case 'funcarg': return funcargs;
            }
        }
        const replaceKeySets = (base: string) => {
            return ReaderUtil.replaceKeySets(base, appCache);
        }
        const root = getTargets().find(t => t.field.id === chooser.rootId);
        assert(root != null, 'root is null.');

        /** 書き換えするためディープコピー */
        let curField = JSON.parse(JSON.stringify(root.field)) as ModelUtil.NodeModelField;
        let curValue = root.value;

        chooser.levelProps.forEach((l, i) => {
            if (curField.array >= 1) {
                let index = l;
                index = replaceKeySets(index);
                // 式の適用
                index = DataUtil.applyFormula(index);
                assert(!isNaN(Number(index)), `${l}を数値に変換できない。[${index}], buildProps: ${appCache.dynamics.funcargs.map(a => a.field.id)}`);
                curValue = curValue[index];
                curField.array--;
            } else if (curField.dataType === 'struct') {
                const model = structs.find(m => m.id === curField.structId);
                assert(model != undefined, 'model is undefined.');
                const nextField = model.fields.find(f => f.id === l);
                assert(nextField != undefined, 'nextField is undefined.');
                curValue = curValue[l];
                curField = JSON.parse(JSON.stringify(nextField));
            }
        });
        return curValue;
    }

    /**
     * フォーカスした変数に対しての操作ユーティリティを生成する
     * @param focusData フォーカスデータ
     * @param appCache ビルドプロパティ
     * @returns 生成したユーティリティ関数群
     */
    export const getChoosedFocusUtil = (
        focusData: NodeFocus.Data,
        appCache: GlobalAppCache
    ): {
        executeAssign: (assignVal: any) => void,
        getFocusObject: () => any,
        getFocusFieldType: () => ModelUtil.NodeModelField
    } => {
        const globalStates = appCache.appStates;
        const variables = appCache.dynamics.variables.concat(appCache.dynamics.prpflds, appCache.dynamics.funcargs);
        // const prpflds = appCache.dynamics.prpflds;
        // const funcargs = appCache.dynamics.funcargs;
        const structs = appCache.globalFixedProps.structs;
        const dynamicProps = appCache.dynamics;

        const getTargets = () => {
            switch (focusData.target) {
                case 'state': return globalStates.concat(dynamicProps.componentStates);
                case 'variable': return variables;
                // case 'comparg': return prpflds;
                // case 'funcarg': return funcargs;
            }
        }
        const root = getTargets().find(t => t.field.id === focusData.rootId);
        assert(root != null, `root is null. rootId:[${focusData.rootId}], list:[${getTargets().map(t => t.field.id).join(',')}]`);

        /** 書き換えするためディープコピー */
        let curField = JSON.parse(JSON.stringify(root.field)) as ModelUtil.NodeModelField;
        // const isRootObject = ModelUtil.isObjectField(curField);
        // const valueObject = root.value;
        let curValue = root.value;

        let executeAssign: null | ((assignVal: any) => void) = null;
        let getFocusObject: null | (() => any) = null;
        let getFocusFieldType: null | (() => ModelUtil.NodeModelField) = null;

        focusData.levelProps.some((l, i) => {
            const isTail = i === focusData.levelProps.length - 1;
            // 配列に対して
            if (curField.array >= 1) {
                // let index = l;
                // index = replaceKeySets(index);
                // // 式の適用
                // index = DataUtil.applyFormula(index);
                const index = getArgsFormatFromFormula(l, appCache);

                // indexが数値であることを検証
                DataUtil.testAcceptAssign({ dataType: 'number', array: 0 }, index);
                if (isTail) {
                    const assignRef = curValue;
                    executeAssign = (assignVal: any) => {
                        // console.log(assignRef);
                        assignRef[Number(index)] = assignVal;
                        // (curValue as any[]).splice(Number(index), 1, assignVal);
                        // console.log(curValue);
                        // console.log(assignVal);
                    };
                }
                curValue = curValue[Number(index)];
                curField.array--;
            } else if (curField.dataType === 'struct') {
                // JSONオブジェクトに対して
                const model = structs.find(m => m.id === curField.structId);
                assert(model != undefined, 'model is undefined.');
                const nextField = model.fields.find(f => f.id === l);
                assert(nextField != undefined, 'nextField is undefined.');

                if (isTail) {
                    const assignRef = curValue;
                    executeAssign = (assignVal: any) => {
                        // console.log(assignRef);
                        assignRef[l] = assignVal;
                    };
                }
                curValue = curValue[l];
                curField = JSON.parse(JSON.stringify(nextField));
            }
            if (executeAssign != null) return true;
        });
        // ルート要素がターゲットの場合
        if (executeAssign == null) {
            executeAssign = (assignVal: any) => {
                DataUtil.testAcceptAssign(root.field, assignVal);
                root.value = assignVal;
            }
        }
        getFocusObject = () => {
            return curValue;
        }
        getFocusFieldType = () => {
            return curField;
        }
        return { executeAssign, getFocusObject, getFocusFieldType }
    }

    /**
     * assign要素の更新処理を実行する
     * @param assign 要素プロパティ
     * @param buildProps ビルド用のキーセット群
     * @param eventArguments イベントの引数（イベントのプロシージャでのみ利用）
     */
    export const executeAssign = (assign: ModelUtil.NodeAssignBak, buildProps: GlobalAppCache, eventArguments: EventArgument[]) => {
        const globalStates = buildProps.appStates;
        const caches = buildProps.dynamics.variables;
        const prpflds = buildProps.dynamics.prpflds;
        const funcargs = buildProps.dynamics.funcargs;
        const models = buildProps.globalFixedProps.structs;
        const dynamicProps = buildProps.dynamics;

        dynamicProps.funcargs = eventArguments;
        const replaceKeySets = (base: string) => {
            return ReaderUtil.replaceKeySets(base, buildProps);
        }

        const dest = caches.find(c => c.field.id === assign.dest);
        assert(dest != undefined, 'accepter is undefined.');

        const getTargets = () => {
            switch (assign.target) {
                case 'state': return globalStates;
                case 'variable': return caches;
                // case 'comparg': return prpflds;
                // case 'funcarg': return funcargs;
            }
        }
        const root = getTargets().find(t => t.field.id === assign.rootId);
        assert(root != null, 'root is null.');

        const variables: ModelUtil.Variables = {
            structs: models,
            caches: dynamicProps.variables.map(c => c.field),
            propFields: dynamicProps.prpflds.map(pf => pf.field),
            states: buildProps.globalFixedProps.states
        };

        const chooser: VariableChooser.Chooser = {
            ...assign,
            levelProps: assign.levelProps.map(l => {
                let index = l;
                index = replaceKeySets(index);
                // 式の適用
                index = DataUtil.applyFormula(index);
                return index;
            })
        }
        let assignValue = ModelElementUtil.getChoosedValue(root.value, chooser, variables);
        if (assign.cloneType != undefined && assign.cloneType === 'deep') {
            assignValue = JSON.parse(JSON.stringify(assignValue));
        }
        dest.value = assignValue;
    }


    export const isTrue = (base: string, buildProps: ReaderUtil.GlobalAppCache) => {

        const [formula, args] = getArgsFormatFromFormula(base, buildProps);

        // 式の適用
        const condition = DataUtil.applyFormulaArgs(args, formula);

        assert(typeof condition === 'boolean', `condition is not boolean.[${condition}]`);
        return Boolean(condition);
    }

    // export const isComponent = (tracer: Tracer, upperNum: number) => {
    //     let cur = tracer.cur;
    //     for (let i = 0; i < upperNum; i++) {
    //         assert(tracer.parent != null, 'tracer.parent is null.');
    //         cur = tracer.parent.cur;
    //     }
    //     assert(cur != null, 'cur is null.');
    //     const type = cur.type;
    //     const compTypes: ModelUtil.NodeType[] = ['elements', 'div'];
    //     const retentionTypes: ModelUtil.NodeType[] = ['retention', 'proc'];
    //     if (compTypes.includes(type)) return true;
    //     else if (retentionTypes.includes(type)) return false;
    //     else throw new Error('想定していないtypeです。');
    // }

    export const getAppliedStyleSources = (refers: StyleChooser.Refer[], appCache: ReaderUtil.GlobalAppCache) => {
        return refers.map(refer => {
            // 適用条件が設定されている場合
            if (refer.accept != undefined) {
                let fml = refer.accept;
                // 条件を満たしていない場合、空文字を返す。
                if (!isTrue(fml, appCache)) return '';
            }
            // スタイルの取得
            const style = appCache.dynamics.styles.find(d => d.id === refer.refId);
            assert(
                style != undefined,
                AssertUtil.errorFindFromArray('style', refer.refId, appCache.dynamics.styles.map(s => s.id))
            );
            // ベースのソースにスタイル引数を適用
            const fmls = JSON.parse(JSON.stringify(style.fmls)) as StyleFormulaDefiner.Record[];
            let src = style.src;
            style.args.forEach(arg => {
                const argValue = refer.args.find(a => a.key === arg.id);
                if (argValue == undefined) return 1;

                let val = argValue.val ?? arg.defVal;
                // 初期値が設定されていない場合、引数が必須入力となるため、undefinedにはなりえない
                assert(val != undefined, '初期値が設定されていない場合、引数が必須入力となるため、valがundefinedにはなりえない。');
                val = replaceKeySets(val, appCache);
                // // 式の適用
                // val = DataUtil.applyFormula(val);
                // スタイル引数を個別に適用
                src = src.replaceAll(`__${PrefixUtil.STYLE_ARGUMENT}.${arg.id}__`, val);
                fmls.forEach(fml => {
                    // fml.fml = fml.fml.replaceAll(`\${${PrefixUtil.STYLE_ARGUMENT}.${arg.id}}`, val as string);
                    fml.fml = fml.fml.replaceAll(`__${PrefixUtil.STYLE_ARGUMENT}.${arg.id}__`, val as string);
                });
            });
            fmls.forEach(fml => {
                let val = fml.fml;
                // 式の適用
                val = DataUtil.applyFormula(val);
                // src = src.replaceAll(`\${${PrefixUtil.STYLE_FOUMULA}.${fml.id}}`, val);
                // console.log(src);
                // console.log(`__${PrefixUtil.STYLE_FOUMULA}.${fml.id}__`);
                src = src.replaceAll(`__${PrefixUtil.STYLE_FOUMULA}.${fml.id}__`, val);
                // console.log(src);
            });
            // src = replaceKeySets(src, buildProps);
            return src;
        });
    }

    /**
     * 関数の定義から、実行可能な関数を生成して返す。
     * @param props 
     * @returns 
     */
    export const generateCallbackFromDispatcher = (props: {
        appCache: ReaderUtil.GlobalAppCache,
        dispatch: TagUtil.Dispatch
    }) => {
        return () => {
            // console.log(props.listener.dsptId);
            const dispatchKey = props.dispatch.dsptId;
            const prefix = dispatchKey.split('.')[0];
            const eventKey = dispatchKey.split('.')[1];
            // console.log(props.buildProps.dynamicProps);
            const getTargetList = () => {
                switch (prefix) {
                    case PrefixUtil.FUNCTION: return props.appCache.dynamics.funcs;
                    case PrefixUtil.PRPCLBK: return props.appCache.dynamics.prpclbks;
                }
                throw new Error(`prefixの値が不正。(prefix: '${prefix}')`);
            }
            const targetList = getTargetList();
            const dispatch = targetList.find(d => d.id === eventKey);
            assert(dispatch != undefined, `dispatchが見つからない。${eventKey}: [${targetList.map(d => d.id).join(', ')}]`);

            // const caches = props.buildProps.dynamicProps.caches;
            // const buildInitialStructureObject = props.buildProps.globalFixedProps.buildInitialStructureObject;

            // 引数の登録
            const funcargs = props.appCache.dynamics.funcargs;
            funcargs.length = 0;
            dispatch.funcargs.forEach((arg, i) => {
                const argVal = props.dispatch.args[i];
                funcargs.push({
                    field: arg,
                    value: argVal
                });
            });
            // console.log(`${dispatch.id}: args[${funcargs.length}]`);
            return dispatch.callback();
        }
    }

    // export const getCallbackObjectArgs = (props: {
    //     appCache: ReaderUtil.GlobalAppCache,
    //     dispatch: FuncObject,
    // }) => {
    //     return (...args: any[]) => {
    //         const dispatch = props.dispatch;
    //         // 引数の登録
    //         const funcargs = props.appCache.dynamics.funcargs;
    //         funcargs.length = 0;
    //         dispatch.funcargs.forEach((arg, i) => {
    //             const argVal = args[i];
    //             funcargs.push({
    //                 field: arg,
    //                 value: argVal
    //             });
    //         });
    //         return dispatch.callback(args);
    //     }
    // }

    export const affectOperation = (props: {
        data: NodeFocus.Data,
        appCache: GlobalAppCache
    }) => {
        const focusData = props.data;
        const appCache = props.appCache;
        const replaceKeySets = (base: string) => {
            return ReaderUtil.replaceKeySets(base, appCache);
        }

        const affectWrap = focusData.items.length === 0 ? null : focusData.items[0] as ModelUtil.WrapElement;
        if (affectWrap != null && !affectWrap.disabled) {
            const focusUtil = getChoosedFocusUtil(focusData, appCache);
            const fieldType = focusUtil.getFocusFieldType();

            const execute = () => {
                switch (affectWrap.type) {
                    case 'assign': {
                        const affectData = affectWrap.data as NodeAssign.Data;
                        let assignVal = null;
                        switch (affectData.assignType) {
                            case 'refer': {
                                const objVal = affectData.refer;
                                assert(objVal != undefined, 'objVal is undefined.');
                                assignVal = ReaderUtil.getChoosedValue(objVal, appCache);
                                if (objVal.cloneType === 'deep') {
                                    assignVal = JSON.parse(JSON.stringify(assignVal));
                                }
                                // console.log(assignVal);
                                focusUtil.executeAssign(assignVal);
                            } break;
                            case 'direct': {
                                assert(affectData.direct != undefined, 'affectData.direct is undefined.');
                                assignVal = affectData.direct;
                                switch (fieldType.dataType) {
                                    case 'boolean': {
                                        assignVal = Boolean(assignVal);
                                    } break;
                                    case 'number': {
                                        assignVal = Number(assignVal);
                                    } break;
                                }
                                focusUtil.executeAssign(assignVal);
                            } break;
                            case 'formula': {
                                assert(affectData.formula != undefined, 'affectData.formula is undefined.');
                                const [formula, args] = getArgsFormatFromFormula(affectData.formula, appCache);
                                const obj = DataUtil.applyFormulaArgs(args, formula);
                                // 代入可否の検証
                                DataUtil.testAcceptAssign(fieldType, obj);
                                focusUtil.executeAssign(obj);
                            } break;
                            case 'initial': {
                                if (ModelUtil.isObjectField(fieldType)) {
                                    const fieldObject = buildInitialStructureObject(fieldType, appCache.globalFixedProps.structs);
                                    assignVal = fieldObject.value;
                                } else {
                                    assignVal = NodeField.getDefaultValue(fieldType.dataType);
                                }
                                // console.log(assignVal);
                            } break;
                            default: {
                                throw new Error(`affectData.assignType（${affectData.assignType}）が不正。`);
                            }
                        }
                    } break;
                    case 'asnmtch': {
                        const data = affectWrap.data as NodeAssignMatch.Data;
                        let assignVal = null;
                        const targetList = ReaderUtil.getChoosedValue(data, appCache) as any[];

                        let right = replaceKeySets(`\${${data.right}}`);
                        right = DataUtil.applyFormula(right);
                        const findVal = targetList.find(t => {
                            let left = t;
                            data.left.split('.').forEach(prp => {
                                left = t[prp];
                            });
                            return left === right;
                        });
                        if (findVal != undefined) {
                            assignVal = JSON.parse(JSON.stringify(findVal));
                            focusUtil.executeAssign(assignVal);
                        }
                    } break;
                    case 'mtchidx': {
                        const data = affectWrap.data as NodeAssignMatch.Data;
                        const targetList = ReaderUtil.getChoosedValue(data, appCache) as any[];

                        let right = replaceKeySets(data.right);
                        right = DataUtil.applyFormula(right);
                        const index = targetList.findIndex(t => {
                            let left = t;
                            data.left.split('.').forEach(prp => {
                                left = t[prp];
                            });
                            // console.log(`${left} === ${right}`);
                            return left === right;
                        });
                        focusUtil.executeAssign(index);
                    } break;
                    case 'arradd': {
                        const affectData = affectWrap.data as NodeArrayAdd.Data;
                        let assignVal = null;
                        switch (affectData.assignType) {
                            case 'refer': {
                                const objVal = affectData.objVal;
                                assert(objVal != undefined, 'objVal is undefined.');
                                assignVal = ReaderUtil.getChoosedValue(objVal, appCache);
                                if (objVal.cloneType === 'deep') {
                                    assignVal = JSON.parse(JSON.stringify(assignVal));
                                }
                            } break;
                            case 'formula': {
                                const fmlVal = affectData.fmlVal;
                                assert(fmlVal != undefined, 'fmlVal is undefined.');
                                assignVal = replaceKeySets(fmlVal);
                            }
                        }
                        const targetArray = focusUtil.getFocusObject() as any[];
                        if (affectData.index != undefined) {
                            const index = Number(replaceKeySets(affectData.index));
                            const insertedArray = targetArray.splice(index, 0, assignVal);
                            focusUtil.executeAssign(insertedArray);
                        } else {
                            targetArray.push(assignVal);
                        }
                    } break;
                    case 'arrcat': {
                        const affectData = affectWrap.data as NodeArrayCat.Data;
                        let assignVal = null;
                        const objVal = affectData.objVal;
                        assignVal = ReaderUtil.getChoosedValue(objVal, appCache);
                        if (objVal.cloneType === 'deep') {
                            assignVal = JSON.parse(JSON.stringify(assignVal));
                        }
                        const targetArray = focusUtil.getFocusObject() as any[];
                        if (affectData.index != undefined) {
                            const index = Number(replaceKeySets(affectData.index));
                            const insertedArray = targetArray.splice(index, 0, assignVal);
                            focusUtil.executeAssign(insertedArray);
                        } else {
                            const cacheArray = JSON.parse(JSON.stringify(targetArray));
                            cacheArray.splice(cacheArray.length - 1, 0, ...assignVal);
                            focusUtil.executeAssign(cacheArray);
                        }
                    } break;
                    case 'arrdel': {
                        const affectData = affectWrap.data as NodeArrayDel.Data;
                        const targetArray = focusUtil.getFocusObject() as any[];

                        const index = Number(replaceKeySets(affectData.index));
                        const delCnt = Number(replaceKeySets(affectData.delCnt));
                        targetArray.splice(index, delCnt);
                        focusUtil.executeAssign(targetArray);
                    } break;
                    case 'arreff': {
                        const data = affectWrap.data as NodeArrayEff.Data;
                        const targetArray = focusUtil.getFocusObject() as any[];

                        switch (data.method) {
                            case 'filter': {
                                const condition = data.condition;
                                assert(condition != undefined, 'conditionがundefinedであってはならない。');

                                // console.log(targetArray);
                                const filteredList = targetArray.filter(v => {
                                    const tempBuildProps = { ...appCache };
                                    const tempDynamicProps = { ...tempBuildProps.dynamics };
                                    const temps: FieldObject[] = [];
                                    temps.push({
                                        field: { id: 'v', array: 0, dataType: fieldType.dataType, structId: fieldType.structId },
                                        value: v
                                    });
                                    // console.log(temps);
                                    tempDynamicProps.temps = temps;
                                    tempBuildProps.dynamics = tempDynamicProps;
                                    const isAccept = ReaderUtil.isTrue(condition, tempBuildProps);
                                    // console.log(isAccept);
                                    return isAccept;
                                });
                                // console.log(filteredValue);
                                focusUtil.executeAssign(filteredList);
                            } break;
                            case 'sort': {
                                const sortData = data.sort;
                                assert(sortData != undefined, 'sortDataがundefinedであってはならない。');

                                // console.log(targetArray);
                                const sortedArr = targetArray.sort((a, b) => {
                                    let ret = 0;
                                    sortData.items.some(item => {
                                        if (item.asc === 0 && a[item.prpName] < b[item.prpName] ||
                                            item.asc === 1 && b[item.prpName] < a[item.prpName]
                                        ) {
                                            ret = -1;
                                            return 1;
                                        }
                                    });
                                    return ret;
                                });
                                console.log(sortedArr);
                                focusUtil.executeAssign(sortedArr);
                            } break;
                        }
                    } break;
                    default: throw new Error(`ありえない分岐。[${affectWrap.type}]`);
                }
            }
            try {
                execute();
            } catch (err) {
                appCache.dispStackTrace(affectWrap, err);
            }
        }
    }

    export const getStyleObjFromData = (data: NodeStyle.Data, buildProps: GlobalAppCache): ReaderUtil.StyleObject => {

        const getArgDatas = (refer: StyleChooser.Refer) => {
            const items = buildProps.dynamics.styles;
            const list: NodeStlarg.Data[] = [];
            const style = items.find(it => it.id === refer.refId);
            assert(style != undefined, AssertUtil.errorFindFromArray('style', refer.refId, items.map(i => i.id)));

            style.args.forEach((arg) => {
                // キーが存在しなければ未定義（abstract）なので引数を追加
                if (refer.args.find(a => a.key === arg.id) == undefined) {
                    list.push(arg);
                }
            });
            return list;
        }
        const args = data.inherits.reduce((prev, inh) => {
            return prev.concat(getArgDatas(inh));
        }, [] as NodeStlarg.Data[])
            .concat(
                data.args.map(a => a.data as NodeStlarg.Data)
            );
        const src = ReaderUtil.getAppliedStyleSources(data.inherits, buildProps).concat(
            data.defs.map(def => {
                // console.log(def);
                if (def.val != undefined) {
                    return `${def.prop}: ${def.val};`;
                } else if (def.defs != undefined) {
                    const defs = def.defs.map(d => {
                        return `${d.prop}: ${d.val};`;
                    });
                    return `${def.prop} {\n${defs}\n}`;
                } else throw new Error('valかdefsのどちらかが設定されている必要がある。');
            })
        ).join('\n');
        return {
            id: data.id,
            src,
            fmls: data.fmls,
            args
        };
    }

    export const addFieldObject = (list: FieldObject[], newObj: FieldObject) => {

        const sameIndex = list.findIndex(s => s.field.id === newObj.field.id);
        if (sameIndex !== -1) {
            list.splice(sameIndex, 1);
        }
        list.push(newObj);
    }

    // export const buildListItems = (listProps: NodeTagInput.ListProps, buildProps: GlobalAppCache): string[][] => {
    //     const varAddrArr = listProps.listVar.split('.');
    //     const prefix = varAddrArr[0];
    //     const rootId = varAddrArr[1];
    //     let levelProps: string[] = [];
    //     if (levelProps.length > 3) {
    //         levelProps = varAddrArr.slice(2, varAddrArr.length - 1);
    //     }
    //     const chooser: VariableChooser.Chooser = {
    //         target: getVariableTargetFromPrefix(prefix),
    //         rootId,
    //         levelProps
    //     };
    //     const chooseValue = getChoosedValue(chooser, buildProps);
    //     // console.log(chooseValue);
    //     if (listProps.converter == undefined) {
    //         return (chooseValue as string[]).map(v => [v]);
    //     }
    //     return [];
    // }

    // const getVariableTargetFromPrefix = (prefix: string): VariableChooser.RootTargetType => {
    //     switch (prefix) {
    //         case PrefixUtil.GLOBAL_STATE: return 'state';
    //         case PrefixUtil.VARIABLE: return 'variable';
    //         // case PrefixUtil.PRPFLD: return 'comparg';
    //     }
    //     throw new Error(`prefix:[${prefix}]が不正。`);
    // }
}

export default ReaderUtil;