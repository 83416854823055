import { useContext, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { GlobalContext } from "../entry/systemEntry";
import assert from "assert";
import FormUtil from "../../../common/component/form/formUtiil";
import ServerUtil from "../../../common/serverUtil";
import ModelUtil from "../develop/function/util/modelUtil";
import ModelElementUtil from "../develop/function/util/modelElementUtil";
import NodeApp from "../develop/function/editor/nodeApp";
import NodeLauncher from "../develop/function/editor/release/nodeLauncher";

namespace LibMngTab {

    type LocalState = {
        focusIndex: number;
    }

    export const Componnet = () => {

        const { store, setStore, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            focusIndex: -1
        });
        const invalidate = () => setLocalState({ ...localState });

        const devmdList = useMemo(() => {

            assert(store.user != null, 'store.userはnullであってはならない');
            return store.user.devmdList.map((devmd) => {

                return {
                    ...devmd,
                    project: JSON.parse(ServerUtil.unZip(devmd.source)).rootData as ModelUtil.WrapElement
                };
            });
        }, [store.system.channelIndex]);

        const devmdJsxList = useMemo(() => {
            return devmdList.map((d, i) => {
                const isFocus = localState.focusIndex === i;
                const manageData = store.develop.manageData;
                let isOpen = false;
                if (manageData != null) {
                    isOpen = manageData.seq === d.seq;
                }
                return (
                    <_ItemFrame key={i}
                        isFocus={isFocus}
                        onClick={() => {
                            if (localState.focusIndex === i) {
                                localState.focusIndex = -1;
                            } else {
                                localState.focusIndex = i;
                            }
                            invalidate();
                        }}
                    >
                        <_ColValue width={50}>{d.seq}</_ColValue>
                        <_ColValue select width={200}>{ServerUtil.getHashedKeyFromDevSeq(d.seq)}</_ColValue>
                        <_ColValue select dim={262}>{d.name}</_ColValue>
                        {isOpen ? <_FocusCover /> : <></>}
                    </_ItemFrame>
                );
            })
        }, [localState.focusIndex, store.develop.manageData]);

        return (
            <_Wrap>
                <_Left>
                    <_ListFrame>
                        <_HeaderFrame>
                            <_ColHeader width={50}>Seq</_ColHeader>
                            <_ColHeader width={200}>Hash</_ColHeader>
                            <_ColHeader dim={262}>Define Name</_ColHeader>
                        </_HeaderFrame>
                        {devmdJsxList}
                    </_ListFrame>
                    <FormUtil.ButtonRecord buttons={[
                        {
                            label: 'Open',
                            isEnable: localState.focusIndex !== -1 && store.develop.manageData == null,
                            callback: () => {
                                const devmd = devmdList[localState.focusIndex];
                                store.develop.manageData = devmd;
                                // const json = ServerUtil.b64_to_utf8(devmd.source);
                                const json = ServerUtil.unZip(devmd.source);
                                store.project = JSON.parse(json);
                                dispatcher.updateStore();
                            }
                        },
                        {
                            label: 'Delete',
                            isEnable: localState.focusIndex !== -1,
                            callback: () => {

                            }
                        },
                    ]} />
                </_Left>
                <_Right>
                    {/* <_TextArea
                        readOnly value={(() => {
                            if (localState.focusIndex === -1) return '';
                            const devmd = devmdList[localState.focusIndex];
                            return JSON.stringify(devmd.project, null, 2);
                        })()}
                    /> */}
                    <_Top>
                        <_HeaderFrame bgColor="#8a8461">
                            <_ColHeader width={200}>Hash</_ColHeader>
                            <_ColHeader dim={208}>Launch Name (app id)</_ColHeader>
                        </_HeaderFrame>
                        {(() => {
                            if (localState.focusIndex === -1) return <></>;
                            const devmd = devmdList[localState.focusIndex];

                            // console.log(devmd.project);
                            const launchersWrap = ModelElementUtil.getInnerWrapFixed(devmd.project, 'release', 'launchers');
                            const launchers = (launchersWrap.data as ModelUtil.NodeGenericItems).items.map(launcherWrap => (launcherWrap.data as NodeLauncher.Data));

                            return launchers.map((launcher, i) => {

                                return (
                                    <_ItemFrame key={i}
                                        isFocus={false}
                                        isHover={false}
                                    >
                                        <_ColValue select width={200}>{ServerUtil.getHashedKeyFromDevLaunch(devmd.seq, launcher.no)}</_ColValue>
                                        <_ColValue select dim={208}>{`${launcher.name} (${launcher.appId})`}</_ColValue>
                                    </_ItemFrame>
                                );
                            });
                        })()}
                    </_Top>
                    <_Bottom>
                        <_HeaderFrame bgColor="#8a8461">
                            <_ColHeader width={200}>App Id</_ColHeader>
                            <_ColHeader dim={208}>Size</_ColHeader>
                        </_HeaderFrame>
                        {(() => {
                            if (localState.focusIndex === -1) return <></>;
                            const devmd = devmdList[localState.focusIndex];

                            // console.log(devmd.project);
                            const appsWrap = ModelElementUtil.getInnerWrapFixed(devmd.project, 'apps');
                            const apps = (appsWrap.data as ModelUtil.NodeApps).apps.map(appWrap => appWrap.data as NodeApp.Data);

                            return apps.map((app, i) => {

                                return (
                                    <_ItemFrame key={i}
                                        isFocus={false}
                                        isHover={false}
                                    >
                                        <_ColValue select width={200}>{app.id}</_ColValue>
                                        <_ColValue dim={208}>{JSON.stringify(app).length}</_ColValue>
                                    </_ItemFrame>
                                );
                            });
                        })()}
                    </_Bottom>
                </_Right>
            </_Wrap>
        );
    }
}
export default LibMngTab;


const _Wrap = styled.div<{
}>`
    display: inline-block;
    position: relative;
    margin: 4px 0 0 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    background-color: #cab7b7;
`;

const _Half = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    vertical-align: top;
    padding: 2px;
    box-sizing: border-box;
`;
const _Left = styled(_Half) <{
}>`
    background-color: #b7cac0;
`;
const _ListFrame = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: calc(100% - 40px);
    background-color: #d2e8e9;
    vertical-align: top;
`;
const _HeaderFrame = styled.div<{
    bgColor?: string;
}>`
    display: inline-block;
    width: 100%;
    height: 40px;
    background-color: #4a7980;
    ${props => props.bgColor == undefined ? '' : css`
        background-color: ${props.bgColor};
    `}
    margin-top: 1px;
    vertical-align: top;
`;
const _ItemFrame = styled.div<{
    isFocus: boolean;
    isHover?: boolean;
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 40px;
    background-color: #0000002b;
    ${props => !props.isFocus ? '' : css`
        background-color: #dae480;
    `}
    margin-top: 1px;
    vertical-align: top;
    ${props => !(props.isHover ?? true) ? '' : css`
        &:hover {
            background-color: #98d1d8;
            ${props.isFocus ? '' : css`
                background-color: #ced879e3;
            `}
        }
    `}
`;
const _FocusCover = styled.div<{
}>`
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ff000034;
    z-index: 4;
`;
const _ColBase = styled.div<{
    width?: number;
    dim?: number;
    select?: boolean;
}>`
    display: inline-block;
    ${props => props.width == undefined ? '' : css`
        width: ${props.width}px;        
    `}
    ${props => props.dim == undefined ? '' : css`
        width: calc(100% - ${props.dim}px);        
    `}
    ${props => !props.select ? '' : css`
        user-select: text;
    `}
    height: 34px;
    margin-top: 3px;
    margin-left: 3px;
    padding: 0 0 0 4px;
    box-sizing: border-box;
    vertical-align: top;
    border-radius: 2px;
    font-size: 22px;
    line-height: 34px;
    font-weight: 600;
    overflow: hidden;
`;
const _ColHeader = styled(_ColBase) <{
}>`
    color: #c4cbda;
`;
const _ColValue = styled(_ColBase) <{
}>`
    background-color: #ffffff40;
    color: #66748d;
`;
const _Right = styled(_Half) <{
}>`
    background-color: #d7e1e4;
`;
const _TextArea = styled.textarea<{
}>`
    display: inline-block;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background-color: #cddcdf;
    color: #00245a;
    font-size: 18px;
    resize: none;
    margin: 2px 0 0 2px;
    box-sizing: border-box;
`;


const _Half2 = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 50%;
    vertical-align: top;
    /* padding: 2px; */
    box-sizing: border-box;
`;
const _Top = styled(_Half2) <{
}>`
    background-color: #e3e4d7;
`;
const _Bottom = styled(_Half2) <{
}>`
    background-color: #e3e4d7;
`;

