import { useContext, useEffect, useMemo, useState } from "react";
import { GiTurret } from "react-icons/gi";
import styled, { css } from "styled-components";
import { GlobalContext } from "../entry/systemEntry";
import FormUtil from "../../../common/component/form/formUtiil";
import StoreSystem from "../../redux/store/storeSystem";
import ServerUtil from "../../../common/serverUtil";
import ValidateUtil from "../../../common/component/form/validateUtil";
import { TabbedPane } from "../../../common/component/tab/tabbedPane";
import ConfirmDialog from "../../../common/component/dialog/confirmDialog";
import StoreUser from "../../redux/store/storeUser";
import LocalStrageUtil from "../../util/localStrageUtil";

const WIDTH = 750;
const HEIGHT = 600;
/**
 * アカウントフレームのコンポーネント
 * @returns 
 */
const AccountFrame = () => {
    const { store, setStore } = useContext(GlobalContext);

    const [tabIndex, setTabIndex] = useState(0);

    const [userId, setUserId] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });
    const [oldPass, setOldPass] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });
    const [newPass, setNewPass] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });
    const [newPassConfirm, setNewPassConfirm] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });
    const [email, setEmail] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });


    const [userIdList, setUserIdList] = useState<string[]>([]);
    const [emailList, setEmailList] = useState<string[]>([]);

    const [isModify, setModify] = useState(false);
    const loginProps = store.user as StoreUser.Props;

    const reloadInfo = () => {
        setUserId({ ...userId, value: loginProps.id });
        setNewPass({ ...newPass, value: '' });
        setNewPassConfirm({ ...newPassConfirm, value: '' });
        setEmail({ ...email, value: loginProps.email });
        setOldPass({ ...oldPass, value: loginProps.password });
    }

    useEffect(() => {
        const region = LocalStrageUtil.getActiveRegionDef();
        ServerUtil.findUserInfoList(region).then(list => {

            const userIdList: string[] = [];
            const emailList: string[] = [];
            list.forEach(info => {
                if (info.id !== loginProps.id) userIdList.push(info.id);
                if (info.email !== '') emailList.push(info.email);
            });
            setUserIdList(userIdList);
            setEmailList(emailList);
        });

        const hash = ServerUtil.getHashedFromUserSeq(loginProps.seq);

        // ユーザページのURLに変更する。
        window.history.replaceState('', '', `/#/user?v=${hash}`);

        reloadInfo();

        // 閉じる際にURLを元に戻す
        return () => {
            // alert('destroy');
            window.history.replaceState('', '', `/#/`);
        }
    }, []);

    const userTabJsx: JSX.Element = (
        <_Wrap>
            <FormUtil.InputTextFormBak
                titleLabel={'user id'}
                checkable={userId}
                setCheckable={setUserId}
                validates={[
                    {
                        checker: (value) => ValidateUtil.checkStringLength(value, 4, 20),
                        errorType: "length"
                    },
                    {
                        checker: (value) => ValidateUtil.checkAsciiChars(value),
                        errorType: "value"
                    },
                    {
                        checker: (value) => !userIdList.includes(value),
                        errorType: "relation"
                    },
                ]}
                width={300}
                marginTop={5}
                isReadOnly={!isModify}
            />
            <FormUtil.InputTextFormBak
                titleLabel={'new pass'}
                isPassword
                checkable={newPass}
                setCheckable={setNewPass}
                validates={[
                    {
                        checker: (value) => ValidateUtil.checkStringLength(value, 4, 20),
                        errorType: "length"
                    },
                    {
                        checker: (value) => value === newPassConfirm.value,
                        errorType: "relation"
                    }
                ]}
                dependency={[newPassConfirm]}
                width={300}
                marginTop={5}
                isReadOnly={!isModify}
            />
            <FormUtil.InputTextFormBak
                titleLabel={'new pass*'}
                isPassword
                checkable={newPassConfirm}
                setCheckable={setNewPassConfirm}
                validates={[
                    {
                        checker: (value) => ValidateUtil.checkStringLength(value, 4, 20),
                        errorType: "length"
                    },
                    {
                        checker: (value) => value === newPass.value,
                        errorType: "relation"
                    }
                ]}
                dependency={[newPass]}
                width={300}
                marginTop={5}
                isReadOnly={!isModify}
            />
            <FormUtil.InputTextFormBak
                titleLabel={'email'}
                checkable={email}
                setCheckable={setEmail}
                validates={[
                    {
                        checker: (value) => ValidateUtil.checkStringLength(value, 4, 40),
                        errorType: "length"
                    },
                    {
                        checker: (value) => {
                            return value.length === 0 || value.split('@').length === 2;
                        },
                        errorType: "value"
                    },
                    {
                        checker: (value) => !emailList.includes(value),
                        errorType: "relation"
                    },
                ]}
                // width={500}
                marginTop={5}
                isReadOnly={!isModify}
            />
            <FormUtil.InputTextFormBak
                titleLabel={'old pass'}
                isPassword
                checkable={oldPass}
                setCheckable={setOldPass}
                validates={[
                    {
                        checker: (value) => value === loginProps.password,
                        errorType: "relation"
                    }
                ]}
                // dependency={[passwordConfirm]}
                width={300}
                marginTop={55}
                isReadOnly={!isModify}
            />
        </_Wrap>
    );

    // 登録ボタンの活性状態
    const permitRegist = (
        userId.errors.length === 0
        && newPass.errors.length === 0
        && newPassConfirm.errors.length === 0
        && email.errors.length === 0
        && oldPass.errors.length === 0
    );

    const updateUserInfo = () => {
        const region = LocalStrageUtil.getActiveRegionDef();
        const passwordStr = newPass.value.length === 0 ? '' : `,password = '${newPass.value}'`;
        ServerUtil.sendQueryRequestToAPI('update',
            `UPDATE user_tbl SET id = '${userId.value}', email = '${email.value}'${passwordStr}
            WHERE seq = ${loginProps?.seq}`, region
        ).then(() => {
            loginProps.id = userId.value;
            if (newPass.value.length > 0) {
                loginProps.password = newPass.value;
            }
            loginProps.email = email.value;
            store.system.dialog = <ConfirmDialog.Component message="Modify successful!" />;
            setStore({...store});
        });
    }

    return (
        <_Frame
            width={WIDTH} height={HEIGHT}
        >
            <_Header>
                <FormUtil.Message
                    message="If you bookmark this page, you can easily login by URL."
                />
            </_Header>
            <_Body>
                <TabbedPane
                    tabElements={[
                        { name: 'User', enable: true, cont: userTabJsx },
                        { name: 'Security', enable: false, cont: <></> },
                    ]}
                    activeNo={tabIndex}
                    selectTabIndex={(index: number) => setTabIndex(index)}
                />
            </_Body>
            <_Footer>
                <FormUtil.ButtonRecord
                    buttons={[
                        {
                            label: 'Close',
                            callback: () => {
                                // dispatcher.system.closeDialog();
                            }
                        },
                        {
                            label: 'Cancel',
                            isEnable: isModify,
                            callback: () => {
                                setModify(false);
                                reloadInfo();
                            }
                        },
                        {
                            label: !isModify ? 'Modify' : 'Regist',
                            isEnable: !isModify || permitRegist,
                            callback: () => {
                                if (!isModify) {
                                    setModify(true);
                                    setOldPass({ ...oldPass, value: '' });
                                }
                                else {
                                    updateUserInfo();
                                }
                            }
                        }
                    ]}
                />
            </_Footer>
        </_Frame>
    );
}


export default AccountFrame;

const _Frame = styled.div<{
    width: number;
    height: number;
}>`
    display: inline-block;
    position: absolute;
    z-index: 11;
    top: calc(50% - ${props => props.height / 2}px);
    left: calc(50% - ${props => props.width / 2}px);
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background-color: #e7e7e7eb;
    border: 1px solid #9e9e9e;
    border-radius: 4px;
`;

const _LogoDiv = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 80px;
    background-color: #eeeaea;
    text-align: center;
`;
const _Header = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 50px;
    /* background-color: #d1d1d1; */
    /* text-align: center; */
`;
const _Body = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: calc(100% - 100px);
    padding: 4px;
    box-sizing: border-box;
    /* background-color: #d1d1d1; */
    text-align: center;
`;


const _Footer = styled.div<{
}>`
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 6px 4px 0 0;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    background-color: #d1d1d1;
    text-align: right;
`;


const _Wrap = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #cecece;
`;