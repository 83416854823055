import styled, { css } from "styled-components";
import { ImUser } from 'react-icons/im'
import { MdOutlineLanguage } from 'react-icons/md'
import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "./systemEntry";
import CanvasUtil from "../../../common/canvas/canvasUtil";
import { TOTAL_HEADER_WIDTH } from "../../../def/systemConst";
import LoginFrame from "../login/loginFrame";
import ServerUtil from "../../../common/serverUtil";
import ConfirmDialog from "../../../common/component/dialog/confirmDialog";

const TotalHeader = () => {

    const { store, setStore } = useContext(GlobalContext);

    const user = store.user;

    const [userId, setUserId] = useState('');

    const logo = useMemo(() => {
        return CanvasUtil.getLogoURL();
    }, []);

    useEffect(() => {
        if (user != null) {
            setUserId('@' + user.id);
        }
    }, [user]);

    const authButtonJsx = user == null ? (
        <_LoginButton onClick={() => {
            store.system.canDialogClose = true;
            store.system.dialog = <LoginFrame />;
            setStore({ ...store });
            // console.log(window.location.origin);
        }}>
            <div><ImUser /></div>
            Login / Sign up
        </_LoginButton>
    ) : (<>
        {/* ログアウト */}
        <_UserName
            isEntry={user != null}
            onClick={() => {
                // store.system.dialog = <AccountFrame />;
                setStore({ ...store });
            }}
        >{userId}</_UserName>
        <_LoginButton onClick={() => {
            if (store.system.channelIndex === 0) {
                store.system.channelIndex = 1;
            }
            setStore({ ...store });
            setTimeout(() => {
                store.user = null;
                store.system.dialog = <ConfirmDialog.Component
                    message={'Logout.\nSee you next time!'}
                />;
                store.system.logoutActions.forEach(func => func());
                setStore({ ...store });
            }, 1);
        }}>
            <div><ImUser /></div>
            Logout
        </_LoginButton>
    </>);
    return (
        <_Wrap>
            <_Logo src={logo} />
            <_Version>{ServerUtil.VERSION_NAME}</_Version>
            <_Menu>
                {authButtonJsx}
                <_Language>
                    <div><MdOutlineLanguage /></div>
                </_Language>
            </_Menu>
        </_Wrap>
    );
}

export default TotalHeader;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: ${TOTAL_HEADER_WIDTH}px;
    background: linear-gradient(to bottom, #92a7a6, #bbd4d0, #92a7a6);
    /* background-color: #c2c2c2; */
    box-shadow: 0 0 8px 0 #000b3a48;
`;

const _Menu = styled.div`
    display: inline-block;
    /* width: 300px; */
    height: 100%;
    position: absolute;
    right: 30px;
    /* background-color: #ff00002b; */
`

const _MenuItem = styled.div`
    background-color: #c6d1ce;
    &:hover {
        background-color: #cf9bae;
    }
    transition: background-color 800ms;
`

const _LoginButton = styled(_MenuItem)`
    display: inline-block;
    /* width: 240px; */
    padding: 0 30px;
    margin: 5px 10px 0 0;
    height: calc(100% - 10px);
    border-radius: 20px;
    /* background: linear-gradient(to bottom, #e9baba, #d39d9d, #e9baba); */
    /* background-color: #adadad; */
    border: 1px solid #7e7e7e;
    box-sizing: border-box;
    /* box-shadow: 0 0 8px 0 #000b3a48; */
    /* text-shadow: 1px 2px 3px #808080ba; */
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    color: #ffffff;
    vertical-align: top;

    & div {
        display: inline-block;
        /* background-color: #0000007d; */
        margin: 2px 5px 0 0;
        vertical-align: top;
    }
`;

const _UserName = styled.div<{
    isEntry: boolean;
}>`
    display: inline-block;
    /* width: 240px; */
    margin: 5px 20px 0 0;
    height: calc(100% - 10px);
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    color: #01096d92;
    ${props => props.isEntry ? '' : css`
        color: #ff393983;
    `}
    vertical-align: top;
`;

const _Language = styled(_MenuItem)`
    display: inline-block;
    width: ${TOTAL_HEADER_WIDTH - 6}px;
    height: ${TOTAL_HEADER_WIDTH - 6}px;
    margin: 3px 0 0 0;
    border-radius: 50%;
    /* background: linear-gradient(to bottom, #e9baba, #d39d9d, #e9baba); */
    /* background-color: #48aa9d; */
    /* border: 1px solid #7e7e7e; */
    /* box-shadow: 0 0 8px 0 #000b3a48; */
    /* text-shadow: 1px 2px 3px #808080ba; */
    /* text-align: center; */
    font-size: 28px;
    font-weight: 600;
    color: #ffffff;
    & div {
        display: inline-block;
        margin: 2px 0 0 5px;
        /* background-color: #0000007d; */
        vertical-align: top;
    }
`;

const _Logo = styled.img`
    display: inline-block;
    position: relative;
    width: 210px;
    height: 100%;
    padding: 3px 0;
    margin-left: 8px;
    box-sizing: border-box;
    /* background-color: #bc28289f; */
`;

const _Version = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 100px;
    height: 42px;
    line-height: 55px;
    /* background-color: #3506062b; */
    margin-left: 10px;

    font-size: 18px;
    color: #03293fbc;
    font-weight: 600;
`;