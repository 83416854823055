import { useContext, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { GlobalContext } from "../entry/systemEntry";
import FormUtil from "../../../common/component/form/formUtiil";
import ServerUtil from "../../../common/serverUtil";
import ValidateUtil from "../../../common/component/form/validateUtil";
import ConfirmDialog from "../../../common/component/dialog/confirmDialog";
import StoreUser from "../../redux/store/storeUser";
import LocalStrageUtil from "../../util/localStrageUtil";

const LoginLogin = () => {
    const { store, setStore } = useContext(GlobalContext);

    const [isForget, setForget] = useState(false);

    const [userId, setUserId] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });
    const [password, setPassword] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });
    const [email, setEmail] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });
    const [messageList, setMessageList] = useState<string[]>([]);

    const [ls, setLs] = useState<{
        isLock: boolean;
    }>({
        isLock: false
    });

    const region = LocalStrageUtil.getActiveRegionDef();
    useEffect(() => {
        const list: string[] = [];
        if (userId.errors.length > 0) {
            switch (userId.errors[0]) {
                case 'required': list.push('[user id] is required.'); break;
                case 'length': list.push('[user id] set a value between 4 and 20.'); break;
                case 'value': list.push('[user id] accepts only half-width alphanumeric characters.'); break;
                case 'relation': list.push('[user id] already in use.'); break;
            }
        }
        if (password.errors.length > 0) {
            switch (password.errors[0]) {
                case 'required': list.push('[password] is required.'); break;
                case 'length': list.push('[password] set a value between 4 and 20.'); break;
            }
        }
        setMessageList(list);
    }, [userId, password]);

    /**
     * Enterボタン押下時の処理
     */
    const enterAction = () => {
        store.system.canDialogClose = false;
        ls.isLock = true;
        setStore({ ...store });
        (async () => {
            const results = await ServerUtil.findUserInfoLogin(userId.value, password.value, region);
            if (results.length > 0) {
                // localStorage.setItem(LocalStorageUtil.KEY_LOGIN_HASH, ServerUtil.getHashedFromUserSeq(results[0].seq));
                const user = results[0] as StoreUser.Props;
                store.user = user;
                const devmdList = await ServerUtil.findUserDevmdList(results[0].seq, region);
                user.devmdList = devmdList;

                store.system.dialog = <ConfirmDialog.Component
                    message="Login successful!"
                />;
                setStore({ ...store });
            } else {
                setMessageList(['Incorrect user id or password.']);
                // setPassword({ ...password, value: '' });
            }
            store.system.canDialogClose = true;
            ls.isLock = false;
            setStore({ ...store });
        })();
    }

    const isLoginInfoOK = () => {
        return userId.errors.length === 0 && password.errors.length === 0;
    }

    return (<>
        <_Frame isVisible={!isForget}>
            <FormUtil.BorderPanel
                title="Login information"
                innerJsx={<>
                    <FormUtil.Message
                        message={'Please, enter User ID/Pass.'}
                    />
                    <FormUtil.InputTextFormBak
                        placeholder={'user id'}
                        checkable={userId}
                        setCheckable={setUserId}
                        validates={[
                            {
                                checker: (value) => ValidateUtil.checkRequired(value),
                                errorType: "required"
                            },
                            {
                                checker: (value) => ValidateUtil.checkStringLength(value, 4, 20),
                                errorType: "length"
                            },
                            {
                                checker: (value) => ValidateUtil.checkAsciiChars(value),
                                errorType: "value"
                            }
                        ]}
                        width={300}
                        isCenter
                    />
                    <FormUtil.InputTextFormBak
                        placeholder={'password'}
                        isPassword
                        checkable={password}
                        setCheckable={setPassword}
                        validates={[
                            {
                                checker: (value) => ValidateUtil.checkRequired(value),
                                errorType: "required"
                            },
                            {
                                checker: (value) => ValidateUtil.checkStringLength(value, 4, 20),
                                errorType: "length"
                            }
                        ]}
                        width={300}
                        isCenter
                    />
                    <FormUtil.InfoFrame textRecords={messageList} />
                    <FormUtil.ButtonRecord
                        buttons={[
                            { label: 'Enter', isEnable: isLoginInfoOK(), callback: enterAction },
                        ]}
                    />
                </>}
            />
            <FormUtil.MessageLink
                message="Forgot your login information?"
                clickAction={() => {
                    setForget(true);
                }}
            />
        </_Frame>
        <_Frame isVisible={isForget}>
            <FormUtil.BorderPanel
                title="Email"
                innerJsx={<>
                    <FormUtil.Message
                        message={'Submit login information to account address.'}
                    />
                    <FormUtil.InputTextFormBak
                        placeholder={'email'}
                        checkable={email}
                        setCheckable={setEmail}
                        validates={[
                            {
                                checker: (value) => ValidateUtil.checkRequired(value),
                                errorType: "required"
                            },
                            {
                                checker: (value) => ValidateUtil.checkStringLength(value, 4, 40),
                                errorType: "length"
                            },
                            {
                                checker: (value) => {
                                    return value.length === 0 || value.split('@').length === 2;
                                },
                                errorType: "value"
                            }
                        ]}
                        width={500}
                        isCenter
                    />
                    <FormUtil.ButtonRecord
                        buttons={[
                            {
                                label: 'Send', isEnable: email.errors.length === 0, callback: () => {
                                    ServerUtil.findUserInfoFromEmail(email.value, region).then((list) => {
                                        if (list.length > 0) {
                                            const info = list[0];
                                            const hash = ServerUtil.getHashedFromUserSeq(info.seq);
                                            const url = `https://demo-app.licresia.com/#/user?v=${hash}`;
                                            // ServerUtil.sendQueryRequestToMailAPI({
                                            //     from: 'info@licresia.com',
                                            //     to: email.value,
                                            //     subject: '【LiCreSia】support',
                                            //     text: `Your login information is as follows.\n\nURL: ${url}\nUSR ID: ${info.id}`
                                            // });
                                            // dispatcher.system.setDialog(<ConfirmDialog.Component message="Your login information has been sent!" />);
                                        } else {
                                            // dispatcher.system.setDialog(<ConfirmDialog.Component message="This email address is not registered." />);
                                        }
                                    });
                                }
                            }
                        ]}
                    />
                </>}
            />
            <FormUtil.MessageLink
                message="Return to login information."
                clickAction={() => {
                    setForget(false);
                }}
            />
        </_Frame>
        {!ls.isLock ? '' : <_Blind />}
    </>);
};

export default LoginLogin;

const _Frame = styled.div<{
    isVisible: boolean;
}>`
    display: ${props => props.isVisible ? 'block' : 'none'};
    position: relative;
`;

const _Blind = styled.div<{
}>`
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 14;
    background-color: #ffffffae;
`;
