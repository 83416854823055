import assert from "assert";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import ModelUtil from "../util/modelUtil";
import ModelElementUtil from "../util/modelElementUtil";
import NodeCompdef from "../editor/decrare/nodeCompdef";
import Styles from "../../../../../def/design/styles";
import NodeTag from "../editor/ui/tag/nodeTag";
import NodeApp from "../editor/nodeApp";
import PrefixUtil from "../util/prefixUtil";
import NodeFocus from "../editor/proc/focus/nodeFocus";
import NodeFunction from "../editor/var/func/nodeFunction";
import NodeInnerText from "../editor/ui/tag/nodeInnerText";
import NodeIterate from "../editor/nodeIterate";
import NodeAccept from "../editor/condition/nodeAccept";
import NodeCase from "../editor/condition/nodeCase";
import NodeSwitch from "../editor/condition/nodeSwitch";
import NodeWhen from "../editor/condition/nodeWhen";
import NodeCompuse from "../editor/ui/nodeCompuse";
import NodeStyle from "../editor/decrare/nodeStyle";
import NodeEffect from "../editor/linkage/nodeEffect";
import NodeBlock from "../editor/var/nodeBlock";

namespace NodeRelationUtil {

    export type LevelBox = {
        aboveLevel: number;
        owner: TreeUtil.ElementNode;
        nodes: TreeUtil.ElementNode[];
        labelJsx: JSX.Element;
    }
    export type DeclareScope = {
        levels: LevelBox[];
    }

    export type RefarenceItem = {
        idName: string;
        prpName: string;
        addrs: string[];
        node: TreeUtil.ElementNode;
    }

    /**
     * 選択したノードのデータを参照している依存先のノードリストを返す。
     * @param curNode 選択中のノード
     * @returns 参照されている要素リスト
     */
    export const extractRefarence = (curNode: TreeUtil.ElementNode): RefarenceItem[] => {
        const list: RefarenceItem[] = [];

        const curWrap = curNode.data as ModelUtil.WrapElement;

        const run = (node: TreeUtil.ElementNode, add: (node: TreeUtil.ElementNode) => void) => {
            add(node);
            node.children.forEach(c => run(c, add));
        }
        if (ModelElementUtil.nodeTypes('variable', 'state', 'func').includes(curWrap.type)) {
            const keyName = (() => {
                const id = curWrap.data.id;
                assert(id != undefined, `curWrap.dataにidプロパティが存在しない。[${JSON.stringify(curWrap.data).trim()}]`);
                switch (curWrap.type) {
                    case 'variable': return `${PrefixUtil.VARIABLE}.${id}`;
                    case 'state': return `${PrefixUtil.STATE}.${id}`;
                    case 'func': return `${PrefixUtil.FUNCTION}.${id}`;
                }

                throw new Error('keyNameが未定義。');
            })();

            const getStartNode = () => {
                let node: TreeUtil.ElementNode | undefined | null = undefined;
                switch (curWrap.type) {
                    case 'state': {
                        let tempNode: TreeUtil.ElementNode | null = curNode;
                        while (tempNode != null) {
                            if (ModelElementUtil.nodeTypes('app', 'compdef').includes(tempNode.data.type)) {
                                node = tempNode;
                                break;
                            }
                            tempNode = tempNode.parent;
                        }
                    } break;
                    case 'variable': {
                        let tempNode: TreeUtil.ElementNode | null = curNode;
                        while (tempNode != null) {
                            if (ModelElementUtil
                                .nodeTypes('compdef', 'tag', 'bool', 'when', 'switch', 'accept', 'proc', 'iterate')
                                .includes(tempNode.data.type)
                            ) {
                                node = tempNode;
                                break;
                            }
                            tempNode = tempNode.parent;
                        }
                    } break;
                    case 'func': {
                        let tempNode: TreeUtil.ElementNode | null = curNode;
                        while (tempNode != null) {
                            if (ModelElementUtil
                                .nodeTypes('project', 'app', 'compdef', 'tag', 'bool', 'when', 'switch', 'accept', 'proc', 'iterate')
                                .includes(tempNode.data.type)
                            ) {
                                node = tempNode;
                                break;
                            }
                            tempNode = tempNode.parent;
                        }
                    } break;
                }
                if (!node) throw new Error('開始nodeが取得できない。');
                return node;
            }
            run(getStartNode(), (node) => {
                const addList = (idName: string, prpName: string, addrs: string[]) => {
                    list.push({ node, idName, prpName, addrs });
                };

                const wrap = node.data as ModelUtil.WrapElement;
                switch (wrap.type) {
                    case 'focus': {
                        const data = wrap.data as NodeFocus.Data;
                        const curKey = `__${(() => {
                            switch (data.target) {
                                case 'state': return PrefixUtil.STATE;
                                case 'variable': return PrefixUtil.VARIABLE;
                            }
                        })()}.${data.rootId}__`;
                        // console.log(curKey);
                        if (curKey.indexOf(keyName) !== -1) {
                            addList('', 'rootId', [wrap.type]);
                        }
                    } break;
                    // case 'text': {
                    //     const data = wrap.data as NodeInnerText.Data;
                    //     if (data.fml != undefined && data.fml.indexOf(keyName) !== -1) {
                    //         addList('', 'fml', [wrap.type]);
                    //     }
                    // } break;
                    case 'iterate': {
                        const data = wrap.data as NodeIterate.Data;
                        if (data.itrCntFml.indexOf(keyName) !== -1) {
                            addList('', 'itrCntFml', [wrap.type]);
                        }
                    } break;
                    case 'accept': {
                        const data = wrap.data as NodeAccept.Data;
                        if (data.condition.indexOf(keyName) !== -1) {
                            addList('', 'condition', [wrap.type]);
                        }
                    } break;
                    case 'tag': {
                        const data = wrap.data as NodeTag.Data;
                        if (data.attrs != undefined) {
                            if (data.attrs.map(a => a.value).join(' ').indexOf(keyName) !== -1) {
                                addList(`{${data.comment}}`, 'attrs', [wrap.type]);
                            }
                        }
                        data.styles.forEach(style => {
                            style.args.forEach(arg => {
                                if (arg.val != undefined && arg.val.indexOf(keyName) !== -1) {
                                    addList(`${style.refId}-${arg.key}`, 'style', [wrap.type]);
                                }
                            });
                        })
                    } break;
                    case 'text':
                    case 'log':
                    case 'native':
                    case 'return': {
                        const fml = wrap.data.fml;
                        if (fml != undefined && fml.indexOf(keyName) !== -1) {
                            let idName = '';
                            let master = node;
                            let addrs: string[] = [];
                            while (master != null) {
                                const type = master.data.type;
                                addrs.push(type);
                                if (ModelElementUtil.nodeTypes('func', 'tag', 'compdef').includes(type)) break;
                                assert(master.parent != null, 'master.parentがnullであってはならない。');
                                master = master.parent;
                            }
                            addrs = addrs.reverse();
                            switch (master.data.type as ModelUtil.NodeType) {
                                case 'func': {
                                    const data = master.data.data as NodeFunction.Data;
                                    idName = `__${PrefixUtil.FUNCTION}.${data.id}__`;
                                } break;
                                case 'compdef': {
                                    const data = master.data.data as NodeCompdef.Data;
                                    idName = `<${data.id}>`;
                                } break;
                                case 'tag': {
                                    const data = master.data.data as NodeTag.Data;
                                    idName = `{${data.comment}}`;
                                } break;
                            }
                            addList(idName, 'fml', [...addrs])
                        }
                    } break;
                }
            });
            // console.log(list);
        }
        return list;
    }

    /**
     * 選択しているノードのスコープに存在する、参照可能な要素リストを返す。
     * @param curNode 選択中のノード
     * @returns 宣言スコープ情報
     */
    export const extractDeclareScope = (curNode: TreeUtil.ElementNode): DeclareScope => {
        const scope: DeclareScope = {
            levels: [],
        };

        let address: TreeUtil.ElementNode[] = [];
        let cur = curNode;
        while (cur.parent != null) {
            address.push(cur);
            cur = cur.parent;
        }
        address = address.reverse();
        if (address.length === 0) return scope;

        // console.log(address.length);
        const getNodeWrap = (node: TreeUtil.ElementNode) => node.data as ModelUtil.WrapElement;
        const rootNode = cur;
        const firstWay = rootNode.children.find(child => child == address[0]);
        assert(firstWay != null, 'firstWayがundefinedであってはならない。');

        const getBelongLevel = (node: TreeUtil.ElementNode) => {
            // 自身のノード配下をチェックしないように除外する
            return address.slice(0, address.length - 1).findIndex(n => n == node);
        }

        // console.log(address.length);

        const appendStates = (statesNode: TreeUtil.ElementNode, level: LevelBox) => {
            // level.nodes.push(...statesNode.children);
            statesNode.children.forEach(refNode => pushScopeNode({
                list: level.nodes, curNode, refNode
            }));
        }

        const appendDeclares = (declaresNode: TreeUtil.ElementNode, levelBox: LevelBox) => {
            // console.log('appendDeclares');
            const stylesNode = ModelElementUtil.getInnerNodeFixed(declaresNode, 'styles');
            const structsNode = ModelElementUtil.getInnerNodeFixed(declaresNode, 'structs');
            const functionsNode = ModelElementUtil.getInnerNodeFixed(declaresNode, 'funcs');
            const componentsNode = ModelElementUtil.getInnerNodeFixed(declaresNode, 'comps');

            stylesNode.children.forEach(refNode => pushScopeNode({
                list: levelBox.nodes, curNode, refNode
            }));
            structsNode.children.forEach(refNode => pushScopeNode({
                list: levelBox.nodes, curNode, refNode
            }));
            functionsNode.children.forEach(refNode => pushScopeNode({
                list: levelBox.nodes, curNode, refNode
            }));
            componentsNode.children.forEach(refNode => pushScopeNode({
                list: levelBox.nodes, curNode, refNode
            }));

            /**
             * コンポーネント要素（tag, compdef, compuse）配下を再帰的に走査して階層ツリーを構成する。
             * @param compNode 
             * @returns 
             */
            const runComponentNode = (compNode: TreeUtil.ElementNode) => {

                const belongLevel = getBelongLevel(compNode);
                if (belongLevel === -1) return;

                const levelBox: LevelBox = {
                    aboveLevel: belongLevel,
                    owner: compNode,
                    nodes: [],
                    labelJsx: (() => {
                        const data = (compNode.data as ModelUtil.WrapElement).data as NodeCompdef.Data;
                        const partialJsx = data.partialId == undefined ? <></> : <Styles._Span color="#fff01c">{' *'}{data.partialId}</Styles._Span>;
                        return <>
                            Component.
                            <Styles._Span color="#a00">{`${data.id}`}</Styles._Span>
                            {partialJsx}
                        </>
                    })()
                };
                scope.levels.push(levelBox);

                const statesNode = ModelElementUtil.getInnerNodeFixed(compNode, 'store', 'states');
                appendStates(statesNode, levelBox);

                const runInnerNodeRec = (innerNode: TreeUtil.ElementNode) => {
                    const belongLevel = getBelongLevel(innerNode);
                    if (belongLevel === -1) return;

                    const wrap = innerNode.data as ModelUtil.WrapElement;
                    const levelBox: LevelBox = {
                        aboveLevel: belongLevel,
                        owner: innerNode,
                        nodes: [],
                        labelJsx: (() => {
                            switch (wrap.type) {
                                case 'tag': {
                                    const data = wrap.data as NodeTag.Data;
                                    const partialJsx = data.partialId == undefined ? <></> : <Styles._Span color="#fff01c">{' *'}{data.partialId}</Styles._Span>;
                                    return <>
                                        Tag.<Styles._Span color="#00861b">{`${data.element}`}</Styles._Span>
                                        {partialJsx}
                                        {'{'}{data.comment}{'}'}
                                    </>;
                                }
                                case 'compuse': {
                                    const data = wrap.data as NodeCompuse.Data;
                                    return <>
                                        Component Use.<Styles._Span color="#ff9d1c">{data.compId}</Styles._Span>
                                    </>;
                                }
                                case 'accept': {
                                    const data = wrap.data as NodeAccept.Data;
                                    const dispCon = data.condition.split('\n')[0];
                                    return <>Accept (<Styles._Span color="#edebd6">{`${dispCon}`}</Styles._Span>)</>;
                                }
                                case 'iterate': {
                                    const data = wrap.data as NodeIterate.Data;
                                    const dispCon = data.itrCntFml.split('\n')[0];
                                    return <>Iterate [<Styles._Span color="#edebd6">{`${dispCon}`}</Styles._Span>]</>;
                                }
                                case 'case': {
                                    const data = wrap.data as NodeCase.Data;
                                    const detail = (() => {
                                        switch (data.method) {
                                            case 'bool': return <Styles._Span color="#edebd6">{`{${data.condition}}`}</Styles._Span>;
                                            case 'switch': return <Styles._Span color="#edebd6">{`{${data.target}}`}</Styles._Span>;
                                            case 'when': return <></>;
                                        }
                                    })();
                                    return <>Case.<Styles._Span color="#3448ff">{`${data.method}`}</Styles._Span>{detail}</>;
                                }
                                case 'switch': {
                                    const data = wrap.data as NodeSwitch.Data;
                                    return <>
                                        Pattern (<Styles._Span color="#edebd6">{`${data.fml}`}</Styles._Span>)
                                    </>;
                                }
                                case 'when': {
                                    const data = wrap.data as NodeWhen.Data;
                                    return <>
                                        Condition (<Styles._Span color="#edebd6">{`${data.condition}`}</Styles._Span>)
                                    </>;
                                }
                                case 'bool': {
                                    const data = wrap.data as ModelUtil.NodeBoolData;
                                    return <>Bool (<Styles._Span color="#b6e2f2">{`${data.bool}`}</Styles._Span>)</>;
                                }
                                default: {
                                    return <><Styles._Span color="#b58a95">{`${wrap.type}`}</Styles._Span></>;
                                }
                            }
                            // throw new Error(`wrap.type[${wrap.type}]に対応するjsxが未定義。`);
                        })()
                    };
                    if (wrap.type !== 'elements') {
                        scope.levels.push(levelBox);
                    }

                    let childTagNodes = innerNode.children;

                    const retentionNode = innerNode.children.find(tagChild => {
                        return getNodeWrap(tagChild).type === 'retention';
                    });
                    if (retentionNode != undefined) {
                        runProcedureNode(retentionNode, levelBox);

                        const elementsNode = innerNode.children.find(tagChild => {
                            return getNodeWrap(tagChild).type === 'elements';
                        });
                        assert(elementsNode != undefined, 'elementsNodeがundefinedであってはならない。');
                        childTagNodes = elementsNode.children;
                    }

                    // コンポーネントの子要素を再帰的に処理
                    childTagNodes.forEach(childTagNode => {
                        runInnerNodeRec(childTagNode);
                    });
                }

                const retentionNode = ModelElementUtil.getInnerNodeFixed(compNode, 'retention');
                runProcedureNode(retentionNode, levelBox);

                const elementsNode = ModelElementUtil.getInnerNodeFixed(compNode, 'elements');
                runInnerNodeRec(elementsNode);
            }

            /**
             * 処理ノード（proc, retention）配下を再帰的に走査する。
             * @param procNode 起点となる処理ノード
             * @param levelBox 
             */
            const runProcedureNode = (procNode: TreeUtil.ElementNode, levelBox: LevelBox) => {

                const addBranch = (branchNode: TreeUtil.ElementNode, labelJsx: JSX.Element, branchProcNode: TreeUtil.ElementNode) => {

                    const belongLevel = getBelongLevel(branchNode);
                    if (belongLevel === -1) return;

                    const levelBox: LevelBox = {
                        aboveLevel: belongLevel,
                        owner: branchNode,
                        nodes: [],
                        labelJsx
                    };
                    scope.levels.push(levelBox);

                    runProcedureNode(branchProcNode, levelBox);
                }

                procNode.children.forEach(itNode => {
                    const itWrap = itNode.data as ModelUtil.WrapElement;

                    const wrap = getNodeWrap(itNode);
                    switch (wrap.type) {
                        case 'variable':
                        case 'style': {
                            // levelBox.nodes.push(itemNode);
                            pushScopeNode({
                                list: levelBox.nodes, curNode, refNode: itNode
                            });
                        } break;
                        case 'func': {
                            pushScopeNode({
                                list: levelBox.nodes, curNode, refNode: itNode
                            });

                            const data = itWrap.data as NodeFunction.Data;
                            const jsx = <>
                                Function.
                                <Styles._Span color="#aa0088">{`${data.id}`}</Styles._Span>
                            </>;
                            const procNode = ModelElementUtil.getInnerNodeFixed(itNode, 'proc');
                            addBranch(itNode, jsx, procNode);
                        } break;
                        case 'compdef': {
                            pushScopeNode({
                                list: levelBox.nodes, curNode, refNode: itNode
                            });
                            runComponentNode(itNode);
                        } break;
                        case 'effect': {
                            const data = itWrap.data as NodeEffect.Data;
                            const jsx = <>
                                Effect
                                <Styles._Span color="#edebd6">{` {${data.comment}}`}</Styles._Span>
                            </>;
                            const procNode = ModelElementUtil.getInnerNodeFixed(itNode, 'proc');
                            addBranch(itNode, jsx, procNode);
                        } break;
                        case 'accept': {
                            const data = wrap.data as NodeAccept.Data;
                            const dispCon = data.condition.split('\n')[0];
                            const jsx = <>Accept (<Styles._Span color="#edebd6">{`${dispCon}`}</Styles._Span>)</>;
                            addBranch(itNode, jsx, itNode);
                        } break;
                        case 'iterate': {
                            const data = itWrap.data as NodeIterate.Data;
                            const dispCon = data.itrCntFml.split('\n')[0];
                            const jsx = <>Iterate [<Styles._Span color="#edebd6">{`${dispCon}`}</Styles._Span>]</>;
                            addBranch(itNode, jsx, itNode);
                        } break;
                        case 'case': {
                            const data = itWrap.data as NodeCase.Data;
                            const detail = (() => {
                                switch (data.method) {
                                    case 'bool': return <Styles._Span color="#edebd6">{`{${data.condition}}`}</Styles._Span>;
                                    case 'switch': return <Styles._Span color="#edebd6">{`{${data.target}}`}</Styles._Span>;
                                    case 'when': return <></>;
                                }
                            })();
                            const jsx = <>Case.<Styles._Span color="#3448ff">{`${data.method}`}</Styles._Span>{detail}</>;
                            addBranch(itNode, jsx, itNode);
                        } break;
                        case 'bool': {
                            const data = wrap.data as ModelUtil.NodeBoolData;
                            const jsx = <>Bool (<Styles._Span color="#b6e2f2">{`${data.bool}`}</Styles._Span>)</>;
                            addBranch(itNode, jsx, itNode);
                        } break;
                        case 'when': {
                            const data = wrap.data as NodeWhen.Data;
                            const jsx = <>
                                When (<Styles._Span color="#edebd6">{`${data.condition}`}</Styles._Span>)
                            </>;
                            addBranch(itNode, jsx, itNode);
                        } break;
                        case 'switch': {
                            const data = wrap.data as NodeSwitch.Data;
                            const jsx = <>
                                Pattern (<Styles._Span color="#edebd6">{`${data.fml}`}</Styles._Span>)
                            </>;
                            addBranch(itNode, jsx, itNode);
                        } break;
                        case 'block': {
                            const data = itWrap.data as NodeBlock.Data;
                            const jsx = <>
                                Block
                                <Styles._Span color="#edebd6">{` {${data.comment}}`}</Styles._Span>
                            </>;
                            addBranch(itNode, jsx, itNode);
                        } break;
                    }
                });
            }

            // アプリ共通のコンポーネント宣言リスト配下の要素を追加
            componentsNode.children.forEach(compNode => {
                runComponentNode(compNode);
            });
        }

        const addCommonDeclare = () => {
            const declaresNode = ModelElementUtil.getInnerNodeFixed(rootNode, 'common', 'declares');

            const levelBox: LevelBox = {
                aboveLevel: 0,
                owner: rootNode,
                nodes: [],
                labelJsx: (() => {
                    return <><Styles._Span color="#000000">Project Common</Styles._Span></>
                })()
            };
            scope.levels.push(levelBox);
            appendDeclares(declaresNode, levelBox);
        }
        switch (getNodeWrap(firstWay).type) {
            case 'common': {
                addCommonDeclare();
            } break;
            case 'apps': {
                addCommonDeclare();

                const appsNode = ModelElementUtil.getInnerNodeFixed(rootNode, 'apps');

                appsNode.children.forEach(appNode => {

                    const belongLevel = getBelongLevel(appNode);
                    if (belongLevel === -1) return 1;

                    const levelBox: LevelBox = {
                        aboveLevel: belongLevel,
                        owner: appNode,
                        nodes: [],
                        labelJsx: (() => {
                            const data = (appNode.data as ModelUtil.WrapElement).data as NodeApp.Data;
                            return <>App.<Styles._Span color="#00861b">{`${data.id}`}</Styles._Span></>
                        })()
                    };
                    scope.levels.push(levelBox);

                    const statesNode = ModelElementUtil.getInnerNodeFixed(appNode, 'store', 'states');
                    appendStates(statesNode, levelBox);

                    const declaresNode = ModelElementUtil.getInnerNodeFixed(appNode, 'declares');
                    appendDeclares(declaresNode, levelBox);
                });
            } break;
        }

        return scope;
    }

    /**
     * スコープに要素を追加する。
     * @param args 
     */
    const pushScopeNode = (args: {
        list: TreeUtil.ElementNode[];
        curNode: TreeUtil.ElementNode;
        refNode: TreeUtil.ElementNode;
    }) => {
        const refWrap = args.refNode.data as ModelUtil.WrapElement;
        const curWrap = args.curNode.data as ModelUtil.WrapElement;

        let isRefer = false;
        switch (refWrap.type) {
            case 'state':
            case 'func':
            case 'variable': {
                const prefix = (() => {
                    switch (refWrap.type) {
                        case 'state': return PrefixUtil.STATE;
                        case 'variable': return PrefixUtil.VARIABLE;
                        case 'func': return PrefixUtil.FUNCTION;
                    }
                })();
                const id = refWrap.data.id;
                assert(id != undefined, `refWrap.dataにidプロパティが存在しない。[${JSON.stringify(refWrap.data).trim()}]`);
                const keyName = `${prefix}.${id}`;
                const check = (v: string) => {
                    if (v.indexOf(keyName) !== -1) isRefer = true;
                }

                switch (curWrap.type) {
                    case 'focus': {
                        const data = curWrap.data as NodeFocus.Data;
                        const curKey = `__${(() => {
                            switch (data.target) {
                                case 'state': return PrefixUtil.STATE;
                                case 'variable': return PrefixUtil.VARIABLE;
                            }
                        })()}.${data.rootId}__`;
                        // console.log(`${curKey} === ${keyName}`);
                        check(curKey);
                    } break;
                    case 'tag': {
                        const data = curWrap.data as NodeTag.Data;
                        if (data.attrs != undefined) {
                            data.attrs.forEach(a => {
                                check(a.value);
                            });
                        }
                        if (data.memoDeps != undefined) {
                            data.memoDeps.forEach(m => {
                                check(m);
                            });
                        }
                        data.styles.forEach(s => {
                            if (s.accept != undefined) check(s.accept);
                            s.args.forEach(a => {
                                if (a.val != undefined) check(a.val);
                            });
                        })
                    } break;
                    case 'compuse': {
                        const data = curWrap.data as NodeCompuse.Data;
                        data.props.some(prop => {
                            if (prop.indexOf(keyName) !== -1) {
                                isRefer = true;
                                return 1;
                            }
                        });
                    } break;
                    case 'log':
                    case 'return':
                    case 'native': {
                        const fml = curWrap.data.fml;
                        assert(fml != undefined, `curWrap.dataにfmlプロパティが存在しない。[${JSON.stringify(curWrap.data).trim()}]`);
                        check(fml);
                    } break;
                    case 'text': {
                        if(curWrap.data.fml != undefined) {
                            check(curWrap.data.fml);
                        }
                    } break;
                    case 'accept': {
                        const data = curWrap.data as NodeAccept.Data;
                        check(data.condition);
                    } break;
                    case 'case': {
                        const data = curWrap.data as NodeCase.Data;
                        if (data.condition != undefined) check(data.condition);
                        if (data.target != undefined) check(data.target);
                    } break;
                    case 'when': {
                        const data = curWrap.data as NodeWhen.Data;
                        check(data.condition);
                    } break;
                    case 'switch': {
                        const data = curWrap.data as NodeSwitch.Data;
                        check(data.fml);
                    } break;
                }
            } break;
            case 'style': {
                const refStyleData = refWrap.data as NodeStyle.Data;
                const check = (v: string) => {
                    if (v === refStyleData.id) isRefer = true;
                }
                switch (curWrap.type) {
                    case 'style': {
                        const data = curWrap.data as NodeStyle.Data;
                        data.inherits.forEach(s => {
                            check(s.refId);
                        })
                    } break;
                    case 'tag': {
                        const data = curWrap.data as NodeTag.Data;
                        data.styles.forEach(s => {
                            check(s.refId);
                        })
                    } break;
                }
            } break;
        }

        if (isRefer) {
            args.list.push(args.refNode);
        }
    }
};

export default NodeRelationUtil;