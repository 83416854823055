import styled, { css } from "styled-components";
import Styles from "../../../def/design/styles";
import TreeUtil from "../../../common/component/tree/treeUtil";
import { useContext, useEffect, useState } from "react";
import FormUtil from "../../../common/component/form/formUtiil";
import { GlobalContext } from "../entry/systemEntry";
import ModelElementUtil from "../develop/function/util/modelElementUtil";
import StoreProject from "../../redux/store/storeProject";
import ModelUtil from "../develop/function/util/modelUtil";
import { TabbedPane } from "../../../common/component/tab/tabbedPane";
import LibMngTab from "./libMngTab";

const LibraryChannel = () => {
    const { store, setStore, dispatcher } = useContext(GlobalContext);

    return (
        <_TabWrap>
            <TabbedPane
                tabElements={[
                    { name: 'Manage', cont: <LibMngTab.Componnet />, enable: true},
                    // { name: 'Instance', cont: <></>, enable: true },
                    // { name: 'Project', cont: <></>, enable: true },
                ]}
                activeNo={0}
            />
        </_TabWrap>
    );
};

export default LibraryChannel;

const _TabWrap = styled.div<{
}>`
    display: inline-block;
    margin: 4px 0 0 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
`;
