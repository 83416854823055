import { useEffect, useState, useContext, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../../util/modelUtil";
import DataUtil from "../../../../../../../common/dataUtil";
import ModelEditDialog from "../../modelEditDialog";
import ModelElementUtil from "../../../util/modelElementUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import assert from "assert";
import { TabbedPane } from "../../../../../../../common/component/tab/tabbedPane";
import styled from "styled-components";
import ScopeManagerBak from "../../../option/scopeManagerBak";
import StyleChooser from "../../decrare/styleChooser";
import TagUtil from "./tagUtil";
import HtmlUtil from "../../../../../../util/htmlUtl";
import ListManger2 from "../../item/listManager2";
import KeyValueListManager from "../../item/keyValueListManager";

namespace NodeTag {

    type LocalState = {
        tabIndex: number;
        comment: FormUtil.CheckableValue;
        partialId: FormUtil.CheckableValue;
        refId: FormUtil.CheckableValue;
        element: FormUtil.CheckableValue;
        // attrsText: FormUtil.CheckableValue;
        attrProps: {
            isEdit: boolean;
            records: DataUtil.KeyValue[];
        }
        /** メモ化の依存変数リスト */
        memoDeps: FormUtil.CheckableValue;
        designRefs: StyleChooser.ReferState[];
    }

    export type Data = {
        comment: string;
        partialId?: string;
        refId?: string;
        element: string;
        attrs?: DataUtil.KeyValue[];
        memoDeps?: string[];
        styles: StyleChooser.Refer[];
        elements: ModelUtil.WrapElement[];
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;
        const [valueKeys, styleItems, dispatchItems] = useMemo(() => {
            // const funcRootwrap = ModelElementUtil.getAppRootWrap(manageItems.focusNode);

            // const states = ModelElementUtil.getStatesFromApp(funcRootwrap);

            const ownerNode = ModelElementUtil.getOwnerCompFromCurrent(manageItems.focusNode);
            const prpflds = ModelElementUtil.getPrpfldsFromCompdef({ type: 'compdef', data: ownerNode });
            // const actions = ModelElementUtil.getActionsFromCurrent(manageItems.focusNode);
            const caches = ModelElementUtil.getRetentionDatasFromCurrent(manageItems.focusNode, 'variable');

            const scope = store.system.scope;
            const stateValueKeys = scope.stateValueKeys as ScopeManagerBak.ValueKeyField[];
            const variableValueKeys = scope.variableValueKeys as ScopeManagerBak.ValueKeyField[];
            const functionItems = scope.functionItems as ScopeManagerBak.FunctionItem[];
            // const prpfldValueKeys = scope.propFieldValueKeys as ScopeManager.ValueKeyField[];
            // const prpclbkItems = scope.prpclbkItems as ScopeManager.FunctionItem[];
            const valueKeys = stateValueKeys
                .concat(variableValueKeys)
            // .concat(prpfldValueKeys);

            const styleItems = scope.styleItems as ScopeManagerBak.StyleItem[];
            return [valueKeys, styleItems, functionItems];
        }, []);

        const [ls, setLocalState] = useState<LocalState>({
            comment: { value: '', errors: [] },
            partialId: { value: '', errors: [] },
            refId: { value: '', errors: [] },
            element: { value: 'div', errors: [] },
            // attrsText: { value: '', errors: [] },
            attrProps: {
                isEdit: false,
                records: [],
            },
            memoDeps: { value: '', errors: [] },
            tabIndex: 0,
            designRefs: [],
        });
        const invalidate = () => setLocalState({ ...ls });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [
            ls.comment,
            ls.partialId,
            ls.element,
            // ls.attrsText,
            ls.memoDeps,
        ];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                ls.comment.value = data.comment;
                ls.partialId.value = data.partialId ?? '';
                ls.refId.value = data.refId ?? '';
                ls.element.value = data.element;
                // ls.attrsText.value = (() => {
                //     if (data.attrs == undefined) return '';
                //     return data.attrs.map(attr => `${attr.key}:${attr.value}`).join('\n');
                // })();
                ls.attrProps.records = (() => {
                    if (data.attrs == undefined) return [];
                    return JSON.parse(JSON.stringify(data.attrs));
                })();
                ls.memoDeps.value = (() => {
                    if (data.memoDeps == undefined) return '';
                    return data.memoDeps.join('\n');
                })();
                ls.designRefs = StyleChooser.getMappedStyleStates(data.styles, { styleItems, phase: 'tag' });
                invalidate();
            }
        }, []);

        const hasChild = () => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                return data.elements.length >= 1;
            }
            return false;
        };

        const hasErrorInherits = StyleChooser.hasErrorDesignRefers(ls.designRefs);
        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            if (hasErrorInherits) {
                setInputOK(false);
                return;
            }

            let attrs: undefined | DataUtil.KeyValue[] = undefined;
            // if (ls.attrsText.value !== '') {
            //     let hasError = false;
            //     attrs = [];
            //     ls.attrsText.value.split('\n').some(attr => {
            //         const items = attr.split(':');
            //         if (items.length === 2) {
            //             attrs?.push({
            //                 key: items[0], value: items[1]
            //             })
            //         } else {
            //             hasError = true;
            //             return 1;
            //         }
            //     });
            //     if (hasError) {
            //         setInputOK(false);
            //         return;
            //     }
            // }
            if (ls.attrProps.isEdit) {
                setInputOK(false);
                return;
            }
            if (ls.attrProps.records.length > 0) {
                attrs = JSON.parse(JSON.stringify(ls.attrProps.records));
            }
            let memoDeps: undefined | string[] = undefined;
            if (ls.memoDeps.value !== '') {
                memoDeps = ls.memoDeps.value.split('\n');
            }
            setInputOK(true);

            let elements: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                elements = (props.temp.data as Data).elements;
            }
            const data: Data = {
                comment: ls.comment.value,
                partialId: DataUtil.blankToUndefined(ls.partialId.value),
                refId: DataUtil.blankToUndefined(ls.refId.value),
                element: ls.element.value,
                styles: StyleChooser.getMappedReferDatas(ls.designRefs),
                attrs,
                memoDeps,
                elements
            }
            setTempData(data);
        }, [ls]);

        const infoTabJsx = (
            <FormUtil.BorderPanel
                title="information"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Comment"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={ls.comment}
                            setCheckable={(checkable) => {
                                ls.comment = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Element"
                        jsx={<FormUtil.Combobox
                            list={HtmlUtil.ValidHtmlTags.map(tag => ({
                                value: tag,
                                // 子要素がある場合、void elemnentは選択できない
                                isDisabled: hasChild() && HtmlUtil.VoidElements.includes(tag)
                            }))}
                            headBlank
                            width={200}
                            checkable={ls.element}
                            setCheckable={(checkable) => {
                                ls.element = checkable;
                                invalidate();
                            }}
                            extend={(value) => {
                                if (HtmlUtil.VoidElements.includes(value)) {
                                    ls.partialId.value = '';
                                }
                            }}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                            isEnabled={true}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Partial Id"
                        isEnabled={!HtmlUtil.VoidElements.includes(ls.element.value)}
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={ls.partialId}
                            setCheckable={(checkable) => {
                                ls.partialId = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Ref Id"
                        isEnabled={!HtmlUtil.VoidElements.includes(ls.element.value)}
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={ls.refId}
                            setCheckable={(checkable) => {
                                ls.refId = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                        />}
                    />
                    {ls.tabIndex !== 0 ? <></> : StyleChooser.getTotalStyleMonitor(ls.designRefs, { styleItems, phase: 'tag' })}
                </>}
            />
        );

        const attrTab = (<>
            {/* <FormUtil.BorderPanel
                title="attributes"
                height={400}
                innerJsx={<>
                    <FormUtil.TextArea
                        checkable={ls.attrsText}
                        setCheckable={(checkable) => {
                            ls.attrsText = checkable;
                            invalidate();
                        }}
                    />
                </>}
            /> */}
            <KeyValueListManager.Component
                areaName="attributes"
                height={450}
                recieveState={ls.attrProps}
                invalidate={invalidate}
                keySyntax={{color: '#00a500', items:HtmlUtil.eventHandlers, type: 'match'}}
            />
        </>);

        const memoTab = (
            <FormUtil.BorderPanel
                title="dependencies"
                height={400}
                innerJsx={<>
                    <FormUtil.TextArea
                        checkable={ls.memoDeps}
                        setCheckable={(checkable) => {
                            ls.memoDeps = checkable;
                            invalidate();
                        }}
                    />
                </>}
            />
        );

        const InheritTab = StyleChooser.Component({
            localState: { refers: ls.designRefs },
            invalidate,
            props: { styleItems, phase: 'tag' }
        });

        return (<_TabFrame>
            <TabbedPane
                tabElements={[
                    { name: 'Info', cont: <_TabItemWrap>{infoTabJsx}</_TabItemWrap>, enable: !hasErrorInherits },
                    { name: 'Style', cont: <_TabItemWrap>{InheritTab}</_TabItemWrap>, enable: true },
                    { name: 'Attribute', cont: <_TabItemWrap>{attrTab}</_TabItemWrap>, enable: true },
                    { name: 'Memoize', cont: <_TabItemWrap>{memoTab}</_TabItemWrap>, enable: true },
                ]}
                activeNo={ls.tabIndex}
                selectTabIndex={(index) => {
                    ls.tabIndex = index;
                    invalidate();
                }}
            />
        </_TabFrame>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'tag';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeTag;

const _TabFrame = styled.div`
    display: inline-block;
    margin: 3px 0 0 3px;
    width: calc(100% - 6px);
    height: calc(100% - 46px);
`;
const _TabItemWrap = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #c5d3dd;
`;
