import PrefixUtil from "./prefixUtil";
import NodeField from "../editor/var/nodeField";
import NodeFunction from "../editor/var/func/nodeFunction";
import ModelUtil from "./modelUtil";
import ModelElementUtil from "./modelElementUtil";
import NodeStyle from "../editor/decrare/nodeStyle";
import NodeStlarg from "../editor/decrare/nodeStlarg";
import NodeCompdef from "../editor/decrare/nodeCompdef";
import Styles from "../../../../../def/design/styles";

namespace SignatureUtil {

    export const getStateName = (data: NodeField.VariableData) => {

        const array = '[]'.repeat(data.array);
        const [type, color] = data.dataType !== 'struct' ? [data.dataType, "#fddf35"] : [data.structId, "#ff9898"];
        const typeJsx = <Styles._Span color={color}>@{type}{array}</Styles._Span>;
        return <>
            {`__${PrefixUtil.STATE}.`}<Styles._Span color="#b7ff65">{data.id}</Styles._Span>{`__`}: {typeJsx}
        </>
    }
    export const getVariableName = (data: NodeField.VariableData) => {

        const array = '[]'.repeat(data.array);
        const [type, color] = data.dataType !== 'struct' ? [data.dataType, "#fddf35"] : [data.structId, "#ff9898"];
        const typeJsx = <Styles._Span color={color}>@{type}{array}</Styles._Span>;
        return <>
            {`__${PrefixUtil.VARIABLE}.`}<Styles._Span color="#6ffc38">{data.id}</Styles._Span>{`__`}: {typeJsx}
        </>
    }
    export const getStyleName = (data: NodeStyle.Data) => {
        const argNames = data.args.map(arg => {
            if (arg.data == null) return '?';
            else return (arg.data as NodeStlarg.Data).id;
        });
        return <>
            <Styles._Span color="#fcbe38">{data.id}</Styles._Span>{'{'}<Styles._Span color="#ffffff71">{argNames.join(', ')}</Styles._Span>{'}'}
        </>
    }
    export const getCompName = (data: NodeCompdef.Data) => {
        let argsJsx = <></>;
        const propsData = ModelElementUtil.getInnerWrapFixed({ type: 'compdef', data }, 'props').data as ModelUtil.NodeProps;
        const argNames = propsData.props.map(wrap => (wrap.data as ModelUtil.NodeModelField).id);
        argsJsx = <Styles._Span color="#ffffff83">{argNames.join(', ')}</Styles._Span>;
        return <>
            {'<'}<Styles._Span color="#a6e7d7">{data.id}</Styles._Span>{' '}<Styles._Span color="#ffffff71">{argNames.join(' ')}</Styles._Span>{'/>'}
        </>
    }

    export const getFunctionName = (data: NodeFunction.Data) => {
        let argsJsx = <></>;
        const argsData = ModelElementUtil.getInnerWrapFixed({ type: 'func', data }, 'args').data as ModelUtil.ArgsData;
        const argNames = argsData.args.map(wrap => {
            if (wrap.data == null) return '?';
            else return (wrap.data as ModelUtil.NodeModelField).id;
        });
        argsJsx = <Styles._Span color="#ffffff83">{argNames.join(', ')}</Styles._Span>;
        const name = <>{`__${PrefixUtil.FUNCTION}.`}<Styles._Span color="#bc90e0">{data.id}</Styles._Span>{`__(`}{argsJsx}{`)`}</>;
        const getRetJsx = () => {
            const ret = data.ret;
            if (ret == undefined) return <Styles._Span color='#cccccc'>void</Styles._Span>;
            return <><Styles._Span color='#ff9898'>{ModelUtil.getField(ret)}</Styles._Span></>;
        }
        return <>
            {name}{' => '}{getRetJsx()}
        </>
    }

}

export default SignatureUtil;
