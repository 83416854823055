
namespace HtmlUtil {

    export const ValidHtmlTags = [
        'a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'big', 'blockquote', 'body',
        'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'data', 'datalist', 'dd', 'del', 'details',
        'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2',
        'h3', 'h4', 'h5', 'h6', 'head', 'header', 'hgroup', 'hr', 'html', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen',
        'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'menu', 'menuitem', 'meta', 'meter', 'nav', 'noscript', 'object',
        'ol', 'optgroup', 'option', 'output', 'p', 'param', 'picture', 'pre', 'progress', 'q', 'rp', 'rt', 'ruby', 's', 'samp',
        'script', 'section', 'select', 'small', 'source', 'span', 'strong', 'style', 'sub', 'summary', 'sup', 'table', 'tbody',
        'td', 'template', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr'
    ];

    export const VoidElements = [
        'area', 'base', 'br', 'col', 'command', 'embed', 'hr', 'img', 'input', 'keygen', 'link', 'meta', 'param', 'source', 'track', 'wbr'
    ];

    export const globalAttributes = [
        'accessKey', 'className', 'contentEditable', 'contextMenu', 'dir', 'draggable', 'hidden', 'id', 'lang', 'spellCheck',
        'style', 'tabIndex', 'title', 'translate', 'role', 'data-*' // data-* attributes are also commonly used
    ];

    export const eventHandlers = [
        'onCopy', 'onCut', 'onPaste',
        'onCompositionEnd', 'onCompositionStart', 'onCompositionUpdate',
        'onFocus', 'onBlur',
        'onChange', 'onInput', 'onInvalid', 'onReset', 'onSubmit',
        'onClick', 'onContextMenu', 'onDoubleClick', 'onDrag', 'onDragEnd', 'onDragEnter', 'onDragExit', 'onDragLeave', 'onDragOver', 'onDragStart', 'onDrop', 'onMouseDown', 'onMouseEnter', 'onMouseLeave', 'onMouseMove', 'onMouseOut', 'onMouseOver', 'onMouseUp',
        'onSelect',
        'onTouchCancel', 'onTouchEnd', 'onTouchMove', 'onTouchStart',
        'onPointerDown', 'onPointerMove', 'onPointerUp', 'onPointerCancel', 'onPointerEnter', 'onPointerLeave', 'onPointerOver', 'onPointerOut', 'onGotPointerCapture', 'onLostPointerCapture',
        'onScroll',
        'onWheel',
        'onAbort', 'onCanPlay', 'onCanPlayThrough', 'onDurationChange', 'onEmptied', 'onEncrypted', 'onEnded', 'onLoadedData', 'onLoadedMetadata', 'onLoadStart', 'onPause', 'onPlay', 'onPlaying', 'onProgress', 'onRateChange', 'onSeeked', 'onSeeking', 'onStalled', 'onSuspend', 'onTimeUpdate', 'onVolumeChange', 'onWaiting',
        'onLoad', 'onError',
        'onAnimationStart', 'onAnimationEnd', 'onAnimationIteration',
        'onTransitionEnd'
    ];

    export const elementSpecificAttributes = {
        a: ['href', 'target', 'download', 'rel', 'hreflang', 'type'],
        abbr: [],
        address: [],
        area: ['alt', 'coords', 'shape', 'href', 'target', 'download', 'rel'],
        article: [],
        aside: [],
        audio: ['src', 'controls', 'autoplay', 'loop', 'muted', 'preload'],
        b: [],
        base: ['href', 'target'],
        bdi: [],
        bdo: ['dir'],
        blockquote: ['cite'],
        body: ['onafterprint', 'onbeforeprint', 'onbeforeunload', 'onhashchange', 'onlanguagechange', 'onmessage', 'onoffline', 'ononline', 'onpopstate', 'onredo', 'onstorage', 'onundo', 'onunload'],
        br: [],
        button: ['autofocus', 'disabled', 'form', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'name', 'type', 'value'],
        canvas: ['width', 'height'],
        caption: [],
        cite: [],
        code: [],
        col: ['span'],
        colgroup: ['span'],
        data: ['value'],
        datalist: [],
        dd: [],
        del: ['cite', 'datetime'],
        details: ['open'],
        dfn: [],
        dialog: ['open'],
        div: [],
        dl: [],
        dt: [],
        em: [],
        embed: ['src', 'type', 'width', 'height'],
        fieldset: ['disabled', 'form', 'name'],
        figcaption: [],
        figure: [],
        footer: [],
        form: ['accept-charset', 'action', 'autocomplete', 'enctype', 'method', 'name', 'novalidate', 'target'],
        h1: [],
        h2: [],
        h3: [],
        h4: [],
        h5: [],
        h6: [],
        head: [],
        header: [],
        hgroup: [],
        hr: [],
        html: ['manifest'],
        i: [],
        iframe: ['src', 'srcdoc', 'name', 'sandbox', 'seamless', 'allowfullscreen', 'width', 'height', 'referrerpolicy'],
        img: ['alt', 'src', 'srcset', 'sizes', 'crossorigin', 'usemap', 'ismap', 'width', 'height', 'referrerpolicy', 'decoding', 'loading'],
        input: ['accept', 'alt', 'autocomplete', 'autofocus', 'checked', 'dirname', 'disabled', 'form', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'list', 'max', 'maxlength', 'min', 'minlength', 'multiple', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'size', 'src', 'step', 'type', 'value', 'width'],
        ins: ['cite', 'datetime'],
        kbd: [],
        keygen: ['autofocus', 'challenge', 'disabled', 'form', 'keytype', 'name'],
        label: ['for', 'form'],
        legend: [],
        li: ['value'],
        link: ['href', 'crossorigin', 'rel', 'media', 'hreflang', 'type', 'sizes', 'referrerpolicy'],
        main: [],
        map: ['name'],
        mark: [],
        menu: ['type', 'label'],
        menuitem: ['type', 'label', 'icon', 'disabled', 'checked', 'command'],
        meta: ['name', 'http-equiv', 'content', 'charset'],
        meter: ['value', 'min', 'max', 'low', 'high', 'optimum'],
        nav: [],
        noscript: [],
        object: ['data', 'type', 'typemustmatch', 'name', 'usemap', 'form', 'width', 'height'],
        ol: ['reversed', 'start', 'type'],
        optgroup: ['disabled', 'label'],
        option: ['disabled', 'label', 'selected', 'value'],
        output: ['for', 'form', 'name'],
        p: [],
        param: ['name', 'value'],
        picture: [],
        pre: [],
        progress: ['value', 'max'],
        q: ['cite'],
        rp: [],
        rt: [],
        ruby: [],
        s: [],
        samp: [],
        script: ['src', 'type', 'async', 'defer', 'crossorigin', 'integrity', 'referrerpolicy'],
        section: [],
        select: ['autocomplete', 'autofocus', 'disabled', 'form', 'multiple', 'name', 'required', 'size'],
        small: [],
        source: ['src', 'type', 'srcset', 'sizes', 'media'],
        span: [],
        strong: [],
        style: ['media', 'nonce', 'type'],
        sub: [],
        summary: [],
        sup: [],
        table: [],
        tbody: [],
        td: ['colspan', 'rowspan', 'headers'],
        template: [],
        textarea: ['autocomplete', 'autofocus', 'cols', 'dirname', 'disabled', 'form', 'maxlength', 'minlength', 'name', 'placeholder', 'readonly', 'required', 'rows', 'wrap'],
        tfoot: [],
        th: ['colspan', 'rowspan', 'headers', 'scope', 'abbr'],
        thead: [],
        time: ['datetime'],
        title: [],
        tr: []
    }

    export const isValidTagName = (tag: string): tag is keyof typeof elementSpecificAttributes => {
        return tag in elementSpecificAttributes;
    };

    export const getValidAttributes = (tagName: string) => {
        const attributes = [...globalAttributes, ...eventHandlers];

        if (isValidTagName(tagName)) {
            attributes.push(...elementSpecificAttributes[tagName]);
        }

        return attributes;
    };
};

export default HtmlUtil;